.mobile-nav-open {
  padding-top: 50px;
}

.page {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;

  .full-bleed & {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    @media (min-width: $bp-large) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

.page--xl {
  max-width: 1320px;

  @media (min-width: 1628px) {
    max-width: 1600px;
  }
}


.u-flexwrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &.top-align {
    align-items: flex-start;
  }

  &.center-align {
    align-items: center;
  }
}


td > .u-flexwrapper {
  display: block;
  flex-wrap: nowrap;
}

.u-lock {
  @media (max-width: $bp-large) {
    overflow-y: hidden;
  }
}
