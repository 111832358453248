.pagination,
.rgPager {
  background: none !important;
  color: #000;
  cursor: default !important;
  font-size: 14px;
  margin-bottom: 30px;
  overflow: hidden;

  tr {
    background: none !important;
    cursor: default !important;
  }

  td {
    border: 0;
    padding: 20px;
  }

  a {
    border: 1px solid transparent;
    color: inherit !important;
    display: block;
    line-height: 1;
    padding: 8px 12px;
    text-decoration: none;
  }

  .paging-numbers {
    float: left;

    @media (max-width: 767px) {
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
    }
  }

  .paging-numbers a,
  .pagination--number a {
    display: inline-block;
    vertical-align: top;

    span {
      line-height: 1;
    }

    &.pagination--current-page,
    &.rgCurrentPage,
    &:hover {
      border: 1px solid #000;
      color: #000;
      font-weight: bold;
      text-decoration: none;
    }
  }

  .paging-link {
    box-sizing: border-box;
    display: block;
    margin-left: 10px;
    padding: 0 2px;
    position: relative;
    text-transform: uppercase;

    @media (max-width: 767px) {
      width: 40%;
    }
  }

  .paging-all {
    clear: left;

    @media (min-width: 767px) {
      clear: none;
      float: left;
    }
  }

  .prev-results {
    float: right;

    @media (max-width: 767px) {
      float: left;
      text-align: left;
    }

    a:after {
      border: 3px solid transparent;
      border-left: 0;
      border-right: 6px solid #000;
      content: '';
      font-size: 0;
      left: 0;
      line-height: 0;
      margin-top: -4px;
      position: absolute;
      top: 50%;
    }
  }

  .next-results {
    float: right;

    @media (max-width: 767px) {
      text-align: right;
    }

    a:after {
      border: 3px solid transparent;
      border-left: 6px solid #000;
      border-right: 0;
      content: '';
      font-size: 0;
      line-height: 0;
      margin-top: -4px;
      position: absolute;
      right: 0;
      top: 50%;
    }
  }
}
