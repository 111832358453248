.reversed {
  background-color: #000;

  h2,
  h3,
  h4,
  h5 {
    color: #fff;
  }

  h4 {
    border-bottom-color: #fff;
  }

  p {
    color: #cecece;
  }
}

.light {
  background-color: $c-background-grey;
}
