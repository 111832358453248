.poster {
  margin-top: 0;
  position: relative;

  & + & {
    border-top: 1px solid #fff;
  }

  a {
    text-decoration: none;
  }
}

//.poster--overlay {
//
//}

.poster--stacked {

  .object {
    display: flex;
    flex-direction: column;
  }

  .object__info {
    margin-right: 4rem;
    margin-top: 4rem;
    order: 1;
    width: 100%;
    //position: absolute;
    //top: 100%;
  }

  .object__title {
    font-size: 5rem;
    font-weight: 600;
    margin-top: 0;
    order: 2;
  }

  .object__copy {
    margin-top: 4rem;
    order: 3;
  }
}

.poster__image {

  .poster--stacked & {
    height: 62.5rem;
    object-fit: cover;
  }
}

.poster__content {

  .poster--stacked & {
    //background: #fff;
    //height: 100%;
    margin-top: 0;
    padding: 8rem 4rem;
  }

  .poster--overlay & {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    margin-top: 0;
    padding: 5rem;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    @media (min-width: $bp-large) {
      padding: 15rem;
    }
  }
}
