.header {
  background-color: #000;
  margin-top: 0;

  * {
    box-sizing: border-box;
    font-family: 'Gotham Narrow' !important;
  }

  img {
    display: block;
    width: 100%;
  }

  a {
    &:visited {
      color: #fff;
    }
  }

  .page {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    max-width: 1635px !important;
    width: 100%;

    @media (max-width: 1628px) {
      max-width: 1355px !important;
    }
  }

  .banner--title,
  h2 {
    color: inherit;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.1;
    margin: 8px 0 0;

    @media (min-width: $bp-medium) {
      font-size: 36px;
    }


    @media (min-width: $bp-large) {
      font-size: 48px;
      font-weight: 400;
    }

    @media (min-width: $bp-desktop) {
      font-size: 60px;
    }

    @media (min-width: $bp-wide) {
      font-size: 65px;
      margin: 8px 0 0;
      max-width: 960px;
    }

    z-index: 0;
  }

  h3 {
    color: inherit;
    font-size: 24px;
    font-weight: 200;
    line-height: 1.1;
    margin-bottom: 0;
    opacity: 0.6;

    @media (min-width: 700px) {
      font-size: 36px;
    }

    @media (min-width: 1000px) {
      font-size: 48px;
    }

    @media (min-width: 1300px) {
      font-size: 50px;
    }
  }

  p {
    color: inherit;
    margin: 0;
    max-width: 100%;
  }

  .btn {
    margin-top: 64px;
  }

  .page--xl {
    max-width: 1320px;

    @media (min-width: 1628px) {
      max-width: 1600px;
    }
  }


  &.header--home {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    font-size: 14px;
    padding: 0;

    @media (max-width: $bp-wide - 1) {
      background-image: none !important;
    }

    @media (min-width: $bp-wide) {
      .header__middle {
        background-image: none !important;
      }
    }

    @media (min-width: $bp-wide) {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 800px;
    }

    .btn {
      background-color: rgba(0, 0, 0, 0.15);
    }

    .banner--title,
    h2 {

      @media (min-width: 1000px) {
        font-size: 60px;
      }

      @media (min-width: 1300px) {
        font-size: 75px;
      }
    }

    h3 {
      opacity: 0.9;

      @media (min-width: 1000px) {
        opacity: 0.6;
      }
    }
  }
}

.header__top {
    background-color: $headerbackground;
    margin: 0;
    padding: 0;
    position: relative;

    .header--home & {
        @media (min-width: $bp-desktop) {
            border-bottom: 0 none;
            flex: 0 0 125px;
            padding-bottom: 15px;
        }

        @media (min-width: $bp-wide) {
            background-color: rgba(0, 0, 0, 0.5);

            .search-open &,
            .search-active &,
            .meganav-open & {
                background-color: $headerbackground;
            }
        }
    }
}

.header__middle {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: $bp-wide) {
    -ms-flex: 1 1 auto;
    flex: 1 1 100%;
    margin: 0;
    position: relative;
  }
  // @media (min-width: 1615px) {
  //   margin-bottom: 62px;
  //   margin-top: -62px;
  //   transform: translateY(62px);
  // }

  .header--standard & {
    @media (min-width: $bp-desktop) {
      flex: 1 1 100%;
    }
  }

  .header--home & {
    .page {

      @media (min-width: $bp-desktop) {
        align-items: stretch;
        display: flex;
        height: 100%;
      }

      &:before,
      &:after {
        background: rgba(0, 0, 0, 0.5);
        bottom: 0;
        content: '';
        left: auto;
        position: absolute;
        right: auto;
        top: 0;
        width: calc(50% - 680px);
      }

      @media (min-width: 1628px) {
        &:before,
        &:after {
          width: calc(50% - 822px);
        }
      }


      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }
  }
}

.header-middle__wrapper {
  background-color: $c-status-gray;
  border: $headerbordermobile solid #fff;
  flex: 1 1 100%;
  margin-top: 15px;
  width: 100%;

  @media (min-width: $bp-large) {
    border-width: $headerborder;
  }

  @media (max-width: $bp-large) {
    margin-top: 0;
  }
}

.header-middle__hero {
  background-repeat: no-repeat;
  background-size: cover;
}

.header__frame {
  border: $headerbordermobile solid #fff;
  border-bottom: 0;
  min-height: 250px; // stop collapse if no text content
  width: 100%;

  @media (min-width: $bp-medium) {
    min-height: 350px; // stop collapse if no text content
  }

  @media (min-width: $bp-large) {
    align-items: center;
    background-image: url('/Client/IMG/Modular/am-5line-logo.svg');
    background-position: right 78px bottom;
    background-repeat: no-repeat;
    background-size: 352px 106px; //29%
    border-width: $headerborder;
    display: flex;
    overflow: hidden;
    position: relative;

    &:before,
    &:after {
      background: #fff;
      bottom: 0;
      content: '';
      height: 6px;
      position: absolute;
    }

    &:before {
      left: 0;
      width: calc(100% - 420px);
    }

    &:after {
      right: 0;
      width: 206px;
    }
  }

  @media (min-width: $bp-wide) {
    background-position: right 12% bottom;
    background-size: 352px 106px; //27%;
    margin: 14px;

    &:before {
      width: 65%;
    }

    &:after {
      width: 18.75%;
    }
  }

  @media (min-width: 1628px) {
    background-position: right 132px bottom;
    background-size: 447px 128px; //27%;
  }
}

.header__banner {
  color: #fff;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-height: 450px;
  max-width: 1280px;
  padding: 48px 16px;
  width: 100%;

  @media (min-width: 800px) {
    height: 50vh;
    //padding: 24px;
  }
  //@media (min-width: 1300px) {
  //  padding: 0;
  //}
}

.header__bottom {
  background-color: #fff;
  color: #000;
  margin: 0;
  padding: 32px 16px;

  @media (min-width: $bp-medium) {
    padding: 40px 24px;
  }

  @media (min-width: $bp-wide) {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 48px 30px;
  }

  .header--home & {
    @media (min-width: $bp-wide) {
      flex: 0 0 180px;
    }
  }

  .page {
    display: block;
    height: 100%;

    @media (min-width: $bp-large) {
      display: flex;
      flex-wrap: nowrap;
    }

    .left,
    .right {
      align-self: center;
    }
  }

  .left {
    // @media (min-width: $bp-medium) {
    //   padding-left: 16px;
    //   padding-right: 16px;
    // }
    @media (min-width: $bp-large) {
      border-bottom: 0 none;
      flex-basis: 40%;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    @media (min-width: $bp-wide) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .right {
    // @media (min-width: $bp-medium) {
    //   padding-left: 16px;
    //   padding-right: 16px;
    // }
    @media (min-width: $bp-large) {
      flex-basis: 60%;
      padding: 0;
    }

    @media (min-width: $bp-wide) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .header__info {
    border-top: 3px solid #000;
    margin-top: 32px;
    padding: 16px 0;
    width: 100%;

    @media (min-width: $bp-wide) {
      border-top-color: #fff;
    }

    a {
      display: inline-block;
      font-size: 16px;
      margin-top: 16px;
      text-decoration: none;

      &:visited {
        color: #000;

        @media (min-width: $bp-wide) {
          color: #fff;
        }
      }

      &:after {
        background: url('/Client/IMG/Modular/icons/chevron-right-black.svg') right center no-repeat;
        background-size: contain;
        content: '';
        display: inline-block;
        height: 12px;
        margin: 0 0 0 12px;
        opacity: 0.75;
        vertical-align: middle;
        width: 12px;

        @media (min-width: 1280px) {
          background-image: url('/Client/IMG/Modular/icons/chevron-right-white.svg');
        }
      }
    }
  }
}


.title-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    width: 100%;
}

.title-row-item {
}

.title-text {
    margin: 0 1em 0 0;
    justify-content: start;
    flex-grow: 1;
}

.title-lang {
    justify-content: end;
    margin: 0 0 1em 0;
}
