.Rtable {
  display: flex;
  flex-wrap: wrap;
  font-size: 4em;
  left: 3px;
  margin: 0;
  padding: 0;
  position: relative;
  top: 3px;
}

.Rtable-cell {
  -webkit-box-flex: 0;
  border-color: #eee;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  flex-grow: 0;
  font-family: 'Gotham Narrow' !important;
  list-style: none;
  margin-left: 1%;
  margin-right: 1%;
  overflow: hidden;
  text-align: left;
  width: 100%;
}

.Rtable-cell > h1,
.Rtable-cell > h2,
.Rtable-cell > h3,
.Rtable-cell > h4,
.Rtable-cell > h5,
.Rtable-cell > h6 {
  margin: 0;
}

.Rtable-cell--overlay > h1,
.Rtable-cell--overlay > h2,
.Rtable-cell--overlay > h3,
.Rtable-cell--overlay > h4,
.Rtable-cell--overlay > h5,
.Rtable-cell--overlay > h6 {
  border: 0;
  font-weight: 600;
}

.Rtable-cell--overlay,
.Rtable-cell--head {
  font-weight: 600;
}

.Rtable-cell--foot,
.Rtable-cell--overlay,
.Rtable-cell--head {
  border: 0;
  padding: 0;
}

.Rtable-cell--head {
  color: #fff !important;
}

.Rtable-cell--head h4 {
  border: 0;
  padding-bottom: 0;
}

.Rtable--2cols > .Rtable-cell {
  width: 48%;
}

.Rtable--3cols > .Rtable-cell {
  width: 31%;
}

.Rtable--4cols > .Rtable-cell {
  width: 22%;
}

.Rtable--5cols > .Rtable-cell {
  width: 17%;
}

.Rtable--6cols > .Rtable-cell {
  width: 13%;
}

@media all and (max-width: 1200px) {
  .Rtable--collapse {
    display: block;
  }

  .Rtable--collapse > .Rtable-cell {
    width: 100% !important;
  }
}

.no-flexbox .Rtable {
  display: block;
}

.no-flexbox .Rtable > .Rtable-cell {
  width: 100%;
}

@media all and (max-width: 1200px) {
  .hiddenSmall {
    display: none;
  }
}

@media all and (max-width: 1200px) {
  .Rtable-cell--overlay {
    display: none;
  }
}

.Tablist {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  display: flex;
  flex-direction: row;
  margin-left: 1%;
  margin-right: -1%;
}

@media all and (min-width: 1200px) {
  .Tablist {
    display: none;
  }
}

.Tab {
  background-color: #fff;
  border: 0;
  border-bottom: 5px solid #fff;
  box-sizing: content-box;
  color:#000!important;
  flex: 1;
  font-family: 'Gotham Narrow' !important;
  padding-bottom: 5px;
}

.Tab

.Tab > h1,
.Tab > h2,
.Tab > h3,
.Tab > h4,
.Tab > h5,
.Tab > h6 {
  font-size: 10rem;
  font-weight: 600;
}

.Tab:hover {
  border-color: #ccc;
}

    .Tab[aria-selected='true'],
    .Tab:active,
    .Tab:focus {
        border-color: #000;
        color: #000 !important;
        outline: none;
    }

.Tab[aria-selected='true'] {
  cursor: default;
}

.js-RtableTabs {
  min-width: 240px;
}
