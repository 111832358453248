.train {
  width: 100%;
}

.train__image {
  margin-top: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  figure {
    display: block;
    width: 100%;

    img {
      width: 100%;

      @supports(object-fit: contain) {
        height: 100%;
        object-fit: contain;
        object-position: top;
      }
    }
  }
}

.train__content {
  background-color: #fff;
  margin-top: 0;
  max-width: 1280px;
  padding: 12rem 1rem;
  position: relative;
  transform: translate3d(0, 0, 0);
  z-index: 2;

  @media (min-width: 800px) {

    @supports (-ms-accelerator: true) {
      figure {
        height: 80vh;
      }
    }

    margin-left: auto;
    margin-right: auto;
    padding: 25rem 25rem 40rem;
    width: 90%;
  }
}
