.section-menu {

  // @media (max-width: $bp-medium) {
  //   margin-left: -$sectionsidepaddingmobile;
  //   margin-right: -$sectionsidepaddingmobile;
  // }

  &.sticky {
    .left,
    .right {
      position: sticky;
      top: 0;
      transform: translate3d(0, 0, 0);

      a {
        text-decoration: none;
      }
    }
  }

  .page {
    align-items: flex-start; // need this for sticky position to work
    display: flex;
    flex-wrap: wrap;

    & + .page {
      margin-top: 36rem;
    }
  }

  figure {
    height: 0;
    padding-bottom: 50%;
    position: relative;
    width: 100%;

    img {
      bottom: 0;
      display: block;
      height: 100%;
      left: 0;
      object-fit: cover;
      object-position: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }

  //.frame {
  //  & + .frame {
  //    border-top: 1px solid #fff;
  //  }
  //}
}

.section-item {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0;
  min-height: 100rem;
  position: relative;

  & > a {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    text-decoration: none;
    top: 0;
    transition: background-color 100ms ease-in;
    z-index: 1;

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }



  .object {
    background: 0 none;
    color: #fff;
    height: 100%;
    position: relative;
    z-index: 2;
  }

  @media (min-width: $bp-medium) {

    .object__info {
      float: left;
      width: 35%;
    }

    .object__title,
    .object__subtitle {
      margin-top: 0;
    }

    .object__subtitle + .object-inner-wrap {
      margin-top: 12rem;
    }

    .object__subtitle,
    .object-inner-wrap {
      float: right;
      width: 60%;
    }
  }
}
