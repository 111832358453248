fieldset {
  border: 0;
  margin: 20px 0;
}

legend {
  display: block;
  font-size: 22px;
  margin: 20px 0;
}

.field {
  clear: both;
  display: block;
  margin: 6em 0; // remove to avoid extra space when field container is empty
  position: relative;


  > div.EditingFormControlNestedControl {
    margin-bottom: 1em; // replace container margin-bottom
  }

  input[type='search'],
  input[type='url'] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }

  &.focus {
    background: #fffadc;
  }

  &:empty {
    margin: 0;
  }

  .edited {
    font-weight: bold;
    margin: 7px 0;

    .field-source {
      display: block;
      font-weight: normal;
    }
  }

  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='search'],
  input[type='url'],
  input[type='number'],
  input[type='tel'],
  textarea,
  select {
    background: $form-input-background;
    border: $form-input-border;
    border-radius: 0;
    box-sizing: border-box;
    color: $form-input-text-colour;
    font-family: 'Gotham Narrow A', 'Gotham Narrow B', Arial, Helvetica, sans-serif;
    font-size: 5em;
    margin: 0;
    max-width: $form-input-max-width !important;
    padding: $form-input-padding !important;
    width: $form-input-width !important;
  }

  select {
    padding: 0;
    width: $form-input-select-width;
  }

  .DropDownField {
    height: auto;
  }

  .CalendarIcon {
    margin: 0 12px;
  }

  input[type='text']:focus,
  input[type='password']:focus,
  input[type='email']:focus,
  input[type='search']:focus,
  input[type='url']:focus,
  input[type='number']:focus,
  input[type='tel']:focus,
  textarea:focus {
    outline: none;
  }

  textarea {
    font-family: inherit;
    font-size: 5em;
    min-height: 60px;
    width: 80%;
  }

  label,
  .EditingFormLabel,
  .InfoLabel {
    //display: inline-block;
    font-size: 5em;
    font-weight: $font-bold;
    margin-bottom: 5px;

    &.required:after {
      color: $colour-red;
      content: '*';
    }
  }

  span {
    &.required {
      //display: inline-block;
      font-size: 5em;
      font-weight: $font-bold;
      margin-bottom: 5px;

      &:after {
        color: $colour-red;
        content: '*';
      }
    }
  }

  span.checkbox {
    &.required > label:after {
      color: $colour-red;
      content: '*';
    }
  }

  .ExplanationText {
    //display: inline-block;
    font-size: 3em;
    font-weight: $font-bold;
    margin-bottom: 5px;
  }

  .LabelField {
    display: block;
    font-size: 5em;
    font-weight: $font-book;
    margin-bottom: 5px;
  }

  .checkbox label,
  &.radio label,
  .radio label {
    //float: left;
    margin-right: 2em;
  }

  &.radio input[type='radio'],
  .radio input[type='radio'] {
    clear: left;
    //float: left;
  }

  .note {
    color: $gray-light;
    display: block;
    font-size: 13px;
  }

  input[type='checkbox'] {
    display: inline;
    margin-right: 8px;
  }

  .CheckBoxListField {
    label {
      display: inline;
    }
  }

  &.radio.vert,
  &.check.vert {
    td {
      font-weight: $font-book;
      padding: 10px 6px;
    }

    input {
      margin-right: 8px;
      margin-top: 0.5em;
    }
  }

  table {
    background: #eee;
    border-bottom: 1px solid $gray-lighter;
  }

  td {
    border: 0;
    border-top: 1px solid $gray-lighter;

    label {
      font-weight: $font-book;
      margin-bottom: 0;
      margin-left: 27px;
    }
  }

  .Error input,
  .Error select,
  .Error textarea {
    background: #f6a49e;
    border: $border-light solid $colour-red;
  }
}

// Kentico
#aspnetForm {
  line-height: 1.4;
  margin: 0;
}

.EditingFormControlNestedControl {
  padding-bottom: 6px;
}

.EditingFormLabel {
  display: block;
  margin-bottom: 12px;
  padding: 0;
}

.RadioButtonList,
.CheckBoxListField {
  width: 100%;
}

.RadioButtonList td,
.CheckBoxListField td {
  width: 10%;
}

.ErrorLabel,
.error,
.EditingFormErrorLabel {
  clear: left;
  color: $colour-red;
  font-size: 4em;
  margin-top: 5px;
  padding: 0;
}

.fieldRequired {
  color: $colour-red;
  line-height: 1;
}

.horizontal-check-list {
  li,
  input,
  label {
    //display: inline-block !important;
    line-height: 1;
    padding: 0;
    vertical-align: middle;
  }

  li {
    margin: 0 20px 0 0;
  }

  label {
    font-weight: normal;
    margin: 2px 0 0 2px;
  }
}

.date-selector {
  .field {
    width: 100% !important;
  }
}

.charsleft {
  font-size: 10px;
  padding: 10px;
}

//datepicker
.datetime-ui-datepicker {
  max-width: 380px;
}

.datetime-ui-datepicker table {
  border-collapse: collapse;
  font-size: 5em;
  margin: 0 0 0.4em;
  width: 100%;
}

.datetime-ui-widget {
  font-family: 'Gotham Narrow';
  font-size: 1em;
}

.calendar-gmt-info {
  display: none;
}

.action-buttons .btn {
  background-color: $gray-lightess;
}
