// Lists of Items
.item {
  box-sizing: border-box;

  a:not(.button) {
    color: #181818;
    display: block;
    margin: 0;
    overflow: hidden;

    &:visited {
      color: $gray-dark;
    }

    &:hover {
      color: #4354c4;
      text-decoration: none;
    }
  }

  h2 {
    color: inherit;
    font-size: 24px;
    margin: 0 0 2rem;

    a:after {
      content: '\00a0\00bb';
    }
  }
}

.item-view {
  clear: both;
  list-style: none;
  margin: 0 0 5rem;
}



// Collection Items
.search-results {
  .item-view {
    h2 {
      font-size: 4.5rem;
      font-weight: 700;
      margin: 0 0 1.5rem;
    }

    p {
      margin: 0;
    }
  }
}


.item-view__grid {

  @media only screen and (min-width: 768px) {
    .search-results__collections & {
      margin: 0 -10px;
    }
  }

  .item {
    display: inline-block;
    margin: 0 -4px 40px 0;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: top;
    width: 33.33333%;

    @media only screen and (max-width: 767px) {
      padding: 0 0 6px;
      width: 100%;
    }

    .search-results--thumbnail {
      margin-bottom: 12px;

      @media only screen and (min-width: 767px) {
        height: 163px;
      }

      @media only screen and (min-width: 976px) {
        height: 211px;
      }
    }

    .columns {
      float: none;
      margin: 0;
      width: 100%;
    }

    .search-results--info-simple {
      display: block;
    }

    .search-results--info-details {
      display: none;
    }
  }
}


.item-view__compact {
  .item {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }

  h2 {
    margin: 0 !important;
  }

  .left.two.columns {
    display: none;
  }

  .ten.columns {
    float: left;
    padding-left: 12px;
    width: 100%;
  }

  // mobile view
  .search-results--heading {
    @media only screen and (min-width: 767px) {
      float: left;
      padding-right: 12px;
      width: 25%;
    }
  }

  //.status {
  //display: none;
  //}

  .cart-status {
    float: left;

    @media only screen and (min-width: 767px) {
      margin-left: 27%;
    }
  }


  .search-results--info {
    span {
      display: inline;
    }

    @media only screen and (min-width: 767px) {
      float: left;
      width: 70%;
    }
  }

  .search-results--info-simple {
    display: none;
  }

  .search-results--info-details {
    display: block;
  }
}


.item-view__list {
  .item {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }

  p {
    margin-bottom: 2.4rem;
  }

  .search-results--info-simple {
    display: none;
  }
  // mobile view
  @media only screen and (max-width: 767px) {
    .two.columns {
      float: left;
      width: 25%;
    }

    .ten.columns {
      float: left;
      padding-left: 12px;
      width: 70%;
    }
  }

  @media only screen and (max-width: 440px) {
    .two a {
      display: block;
    }
  }
}


.item-view__compact,
.item-view__list {
  .item {
    border-bottom: $border-light solid $gray-lighter;
    overflow: hidden;
  }
}


ul.sections {
  margin: 0 0 20px;
}


// Event Items
// Blog Items
.sections {
  h2 {
    font-size: 6rem;
    font-weight: 700;
    letter-spacing: 0.3px;
    line-height: 1.4;
    margin-bottom: 1rem;
    max-width: 100%;

    @media only screen and (max-width: 767px) {
      margin-top: 1rem;
    }
  }

  li {
    border-top: 1px solid #000;
    overflow: hidden;
    padding: 4rem 0;
  }

  .row {
    padding-bottom: 4rem;
  }

  h6 {
    border-bottom: $border-light solid $gray-lighter;
  }
}


.item.next--page {
  display: none;
  position: relative;

  a {
    bottom: 0;
    left: 0;
    padding: 5rem;
    position: absolute;
    right: 0;
    top: 0;
  }

  span {
    bottom: 0;
    position: absolute;
    right: 6rem;
    text-transform: uppercase;

    &:after {
      border: 3px solid transparent;
      border-left: 6px solid #000;
      border-right: 0;
      content: '';
      font-size: 0;
      left: 100%;
      line-height: 0;
      margin-left: 1.1rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media (min-width: 768px) {
    border: 1px solid #eee;
    display: inline-block;
    height: 163px;
  }

  @media (min-width: 976px) {
    height: 211px;
  }

  .item-view__compact &,
  .item-view__list & {
    display: none !important;
  }
}
