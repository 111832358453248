
/////////////////////////////////////
// Compontents
/////////////////////////////////////

.filters {
  clear: both;
  margin-bottom: 12px;
  overflow: hidden;

  h5 {
    float: left;
    line-height: 1.5em;
    margin: 0;
    padding: 17px 20px 0 0;
    // collections topic pages
    @media only screen and (max-width: 767px) {
      float: none;
    }
  }

  &.block {
    h5 {
      float: none;
      padding-top: 0;
    }
  }

  ul.tags li {
    float: left;
    margin: 6px 12px 6px 0;

    a {
      //      @include border-radius(0);
      background: $white;
      border: 1px solid $gray-dark;
      color: $black;
      display: inline-block;
      margin: 0;
      padding: 8px 14px;
      position: relative;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        background-color: $white;
        text-decoration: none;
      }

      &.active {
        background-color: $gray-lightess;
        border: 1px solid $gray-dark;
        color: $gray-dark;
        font-weight: 800;
      }
    }

    &.other a {
      background: none;
      border: 0;
      text-transform: none;
    }
  }

  ul.tags,
  ul.filter-select,
  ul.events {
    //@include no-bullets;
    list-style: none;
    margin: 0;
  }

  .tags li.more a,
  .tags li.more a,
  .tags li.toggleCalendar a {
    background: none;
    border: 0;
    border-bottom: 1px solid #eee;
    font-size: 15px;
    margin: 9px;
    padding: 0;
    text-transform: none;

    &:hover,
    &:active {
      background: none;
      border-bottom: 1px solid #ccc;
    }
  }


  .menu {
    background: url('/client/img/AucklandMuseum/icons/icons.png') right -150px no-repeat;
    border: 1px solid $black;
    clear: both;
    color: $black;
    cursor: pointer;
    display: none;
    margin: 0 0 12px;
    padding: 6px 12px;
  }
}



.subtitle {
  color: #6d6d6d;
  font-size: 3.5rem;
  line-height: 1;
  margin: 0 0 1.5rem;
  text-transform: uppercase;

  &.centered {
    margin-top: 2.5rem;
  }
}

.subtitle-date {
  font-weight: 700;
}

.lang-select {
  padding-top: 3rem;
}

.cite {
  max-width: 100%;
}

h4.plain {
  border: 0 solid #000;
  font-weight: 700;
  padding-bottom: 0;
}
