figure {
  // display: inline-block;
  & + * {
    margin-top: 8rem;
  }
}

img {
  display: block;
  width: 100%;
}

figcaption {
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 3rem;
  margin-top: 0;
  padding: 3rem;

  p {
    font-size: inherit;
  }

  .credit,
  .copyright,
  .other {
    color: #999;
    display: block;
    margin-top: 1rem;
  }
}

.modular-imagecaption {
  padding: 0 0 0 1em;
}

.icon-wrapper {
  display: inline-block;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon-16 {
  min-height: 4rem;
  min-width: 4rem;
}

.icon-20 {
  min-height: 5rem;
  min-width: 5rem;
}

.icon-32 {
  min-height: 8rem;
  min-width: 8rem;
}

.icon-48 {
  min-height: 12rem;
  min-width: 12rem;
}

.icon-labeled {
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.4px;
  line-height: 1.2;

  svg {
    height: 1em;
    margin-top: 0.1em;
    position: relative;
    width: 1em;
  }

  a {
    display: inline-block;
    font-size: 4rem;
    text-decoration: none !important;
    vertical-align: middle;

    p {
      margin-bottom: 0.2rem;
    }

    h5 {
      margin-bottom: -1rem;
    }
  }
}

img[ci-src] {
  opacity: 0;
}

img.ci-image-loaded {
  opacity: 1;
}

.logomash {
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;


  li {
    display: flex;
    list-style: none;

    img {
      margin: auto;
      max-height: 200px;
      max-width: 200px;
    }
  }

  &.logomash-horizontal {

    ul {
      @media (min-width: $bp-medium) {
        display: flex;
      }
    }

    li {
      align-self: center;
      display: block;
      flex-direction: row;
    }
  }

  &.logomash-vertical li {
    align-self: center;
    display: block;
    flex-direction: column;
  }
}

