.tag {

  &.tag__official {
    background-color: $gray-lightess;
    border-color: #6d6d6d;
  }

  &.tag__search {
    background-color: #ccc;
    display: inline-block;
    vertical-align: middle;

    &:hover {
      background-color: $gray-lightess;
      color: $black;
      content: ' x';
      cursor: pointer;
      display: inline-block;
      font-size: 11px;
      margin-left: 10px;
      position: relative;
      top: -1px;
      vertical-align: top;
    }
  }

  &:hover {
    background-color: $gray-lightess;
    border-color: $gray-lighter;
    color: $black;
    text-decoration: none;
  }

  &.active {
    background-color: $green;
    border: 1px solid $green;
    color: $black;
  }
}

//Record treatment

.tags li {
  float: left;
}
