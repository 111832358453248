.object {
    .left &,
    .right & {
        height: 100%;
        padding: 5rem 5rem 15rem;

        @media (min-width: $bp-large) {
            padding-top: 10rem;
        }
    }

    &.object--small {
        padding: 4rem;
    }

    &.object--white {
        background-color: #fff;
    }


    :last-child {
        margin-bottom: 0;
    }
}

.white .object.object--white {
    padding-left: 0;
    padding-right: 0;
}

.object-wrap {
    display: flex;

    .left,
    .right {
        align-self: stretch;
    }

    & > .left,
    & > .right {
        & > .frame {
            position: sticky;
            top: 0;
        }
    }
}

.object-major-wrap {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 80vh;
    justify-content: flex-start;
    position: relative;

    &.hero-left {
        justify-content: flex-end;
    }

    .object--major {
        background: rgba(#fff, 0.75);
        color: #000;
        flex-basis: 50%;
        height: 100%;
        padding: 12rem;
        z-index: 2;
        // &.object--reversed {
        //   background-color: rgba(#000, 0.75);
        //   color: #000;
        // }
    }

    figure {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
}

.object--slat {
    color: $white;

    @media (max-width: $bp-large - 1) {
        background-color: $gray-darker;
    }

    @media (min-width: $bp-large) {
        background-color: rgba(#000, 0.4);
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }

    @media (min-width: $bp-wide) {
        display: flex;
    }
}

.object__title {

    .object--minor & {
        font-size: 8rem;
        //margin-top: 5rem;
        @media (min-width: $bp-wide) {
            font-size: 8.75rem;
        }
    }

    .object--major & {
        font-size: 10em;
        font-weight: 200;
        line-height: 1.175;
        margin-top: 2rem;

        @media (min-width: $bp-large) {
            margin-top: 1rem;
            font-size: 8rem;
        }

        @media (min-width: $bp-wide) {
            font-size: 16rem;
        }
    }

    .object--medium & {
        font-size: 8.75rem;
        font-weight: 400;
        margin-top: 1rem !important;
    }

    .object--small & {
        font-size: 6rem;
        margin-top: 2rem;
    }

    .object--slat & {
        font-size: 4.5rem;
        font-weight: 400;
        margin-top: 3rem;

        @media (min-width: $bp-wide) {
            font-size: 6rem;
        }

        @media (min-width: $bp-wide) {
            font-size: 8.75rem;
        }
    }
}

.object__subtitle {
    font-weight: 300;
    font-size: 7rem;
    text-align: left;
    max-width: revert;

    .object--small & {
        font-size: 5rem;
        margin-top: 4rem;
        text-align: left;
        max-width: revert;
    }

    .object--minor & {
        font-size: 7rem;
        margin-top: 12rem;
        width: 70%;

        @media (min-width: $bp-small) {
            min-width: 320px;
        }
    }

    .object--medium & {
        margin-top: 12rem;
    }

    .object--major & {
        color: #9fa7ad;
        margin-top: 12rem;

        @media (min-width: $bp-large) {
            margin-top: 2rem;
        }

        @media (min-width: $bp-wide) {
            font-size: 10rem;
            margin-top: 10rem;
        }
    }
}

.object__info {
    display: inline-block;
    font-size: 3.5rem;
    line-height: 1;

    .object--slat & {
        @media (min-width: $bp-wide) {
            padding-right: 4rem;
            width: 50%;
        }
    }
}

.object__extraOne {
    display: block;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 1.2;

    @media (min-width: $bp-wide) {
        font-size: 4rem;
    }

    .object--minor & {
        font-size: 3.5rem;
    }

    .object--small & {
        font-size: 3.5rem;
    }

    .object--slat {
        font-weight: 300;

        @media (max-width: $bp-wide - 1) {
            font-size: 3.5rem;
        }
    }

    &:empty {
        display: none;
    }
}

.object__extraTwo {
    display: block;
    font-size: 3.5rem;
    letter-spacing: 0.5px;
    line-height: 1.2;
}

.object__copy {
    font-size: 3.5rem;
    max-width: 700px;

    .simple-search & {
        @media (max-width: 985px) and (min-width: $bp-wide) {
            display: flex;
            padding: 1em 0;
        }
    }

    .object--major & {
        margin-top: 12rem;

        @media (min-width: $bp-large) {
            font-size: 5rem;
            margin-top: 5rem;
        }

        @media (min-width: $bp-wide) {
            margin-top: 17.5rem;
        }

        .btn {
            margin-top: 5rem;

            @media (min-width: $bp-wide) {
                margin-top: 12rem;
            }
        }
    }

    .object--small & {
        margin-top: 4rem;
    }

    .object--minor & {
        margin-top: 10rem;

        .btn {
            margin-top: 2rem;

            @media (min-width: $bp-wide) {
                margin-top: 5rem;
            }
        }
    }

    .object--medium & {
        margin-top: 10rem;
    }



    :last-child {
        &,
        & :last-child {
            margin-bottom: 0;
        }
    }
}

.object__cta {

    .object--major & {
        margin-top: 20rem;
    }

    .object--medium & {
        margin-top: 10rem;
    }
}

.object-inner-wrap {

    .object--minor & {
        margin-top: 7rem;

        @media (min-width: $bp-wide) {
            margin-top: 12rem;
        }
    }

    .object--slat & {
        @media (min-width: $bp-wide) {
            margin-top: 7rem;
            width: 50%;
        }
    }
}

.object--minor {
    // When a title follows a subtitle,
    // the margins need to be tightened up slightly.
    .object__subtitle + .object-inner-wrap {
        margin-top: 1rem;
    }
}
