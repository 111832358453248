/////////////////////////////////////
// Left Nav
/////////////////////////////////////

.left-nav {
  border-top: $border-heavy solid $black;
  margin: 10rem 0;

  a {
    text-decoration: none;
  }

  @media (max-width: 767px) {
    display: none;
  }

  h2 {
    font-size: 6.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 4rem 0 2rem;
  }

  .subnav {
    border-bottom: $border-light solid $black;
    list-style: none;
    margin: 0 5rem 0 0;

    li {
      border-top: $border-light solid $black;
      margin: 0;

      &:first-child {
        border-top: 0;
      }
      //sub level
      ul {
        background: $gray-lightess;
        list-style: none;
        margin: 0;
        padding: 3px;

        li,
        li:hover,
        a,
        a:hover,
        a:hover,
        a:hover.active,
        a.active {
          border: 0 !important;
          padding: 3px;
        }

        .active:visited {
          color: #000;
        }

        a:visited {
          color: $gray;
        }

        a:hover {
          color: #4354c4 !important;
        }
        //sub sub level
        ul {
          background: $white;
          border: 0;
          border-bottom: $border-light solid $gray;
          border-top: $border-light solid $gray;
          margin: 0 4px;

          a {
            font-size: 13px;
          }
        }
      }
    }

    a {
      border-color: rgba(0, 0, 0, 0);
      display: block;
      font-size: 14px;
      line-height: 1.3em;
      padding: 10px 0;
      transition: border-color 0.2s linear;

      &:visited {
        color: $gray;
      }

      &.active {
        border-bottom: $border-heavy solid $gray-lighter !important;
        border-color: rgba(0, 0, 0, 0.1);
        color: $black;
        font-weight: $font-bold;
        padding: 10px 0 5px;
      }

      &:hover {
        border-bottom: $border-heavy solid $gray-lighter;
        border-color: rgba(0, 0, 0, 0.1);
        color: #4354c4;
        padding: 10px 0 5px;
      }

      h2 {
        color: inherit;
      }
    }
  }

  .menu {
    background: url('/client/img/AucklandMuseum/icons/icons.png') right -200px no-repeat;
    border: 1px solid $black;
    clear: both;
    cursor: pointer;
    display: none;
    float: right;
    height: 32px;
    margin: 6px 0 0 10px;
    overflow: hidden;
    text-indent: -100px;
    width: 32px;
  }
}
