.link-list {
  margin: 12rem 4rem;
  max-width: 400px;
}

.link-list__title {
  font-size: 4rem;
  text-transform: uppercase;
}

.link-list__list {
  list-style: none;
}

.link-list__item {
  background-image: url('/images/icons/chevron-right-black.svg');
  background-origin: content-box;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 10px;
  border-top: 2px solid #000;
  margin-top: 0;
  padding: 2rem 2rem 2rem 0;
}

.link-list__link {
  display: block;
  padding-top: 1rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
