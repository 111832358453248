.tnewicon {
  display: inline-block;
  vertical-align: middle;
}

.tnewicon-20 {
  height: 20px;
  width: 20px;
}

.tnewheader {
  background-color: #fff !important;
}

#tnew_inner {
  height: 50px;
}

.tnew_section {
  color: #808080;
  font-size: 16px;
  margin: auto;
  padding: 12px;
  width: 100%;
}

#pnlUser {
  float: left;
}

#pnlCart {
  float: right;
}

#lnkCart {
  color: #808080;
  font-size: 1em;
  padding-left: 6px;
  text-decoration: none;
}

