
.subtitle {
  color: #6d6d6d;
  font-size: 3.5rem;
  line-height: 1;
  margin: 0 0 1.5rem;
  text-transform: uppercase;

  &.centered {
    margin-top: 2.5rem;
  }
}

.subtitle-date {
  font-weight: 700;
}

.lang-select {
  padding-top: 3rem;
}

.cite {
  max-width: 100%;
}

h4.plain {
  border: 0 solid #000;
  font-weight: 700;
  padding-bottom: 0;
}
