.site-menu {
  background-color: #000;
  padding: 6rem;
}

.site-menu__logo {
  width: 100px;

  polygon {
    fill: #fff;
  }
}

.site-menu__navigation {
  margin-top: 0;
}
