.intro-box {
    background-color: #000;
    color: #fff;
    padding-bottom: 50rem;
    text-align: center;

    @media (min-width: $bp-desktop) {
        padding-bottom: 100%;
        position: relative;
        text-align: left;
    }

    :empty {
        display: none;
    }
}

.intro-box__wrapper {
  padding: 15rem 5rem;

  @media (min-width: 800px) {
    bottom: 0;
    padding: 10rem;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @media (min-width: $bp-wide) {
    padding: 15rem;
  }

  :last-child {
    margin-bottom: 0;
  }
}

.intro-box__title {
  font-size: 5rem;
  font-weight: 600;

  @media (min-width: $bp-desktop) {
    font-size: 8.75rem;
  }
}

.intro-box__copy {
  margin-top: 5rem;

  p {
    font-weight: 300;

    @media (min-width: $bp-desktop) {
      font-size: 6rem;
    }

    @media (min-width: $bp-wide) {
      font-size: 8.75rem;
    }
  }
}
