.see-more {
  background-color: #f5f5f5;
  text-align: center;

  .page {
    display: block;
  }

  // TODO: Double check this. It's a bit hard coded.

  p {
    max-width: none;

  }

  .btn {
    margin-top: 10rem;
  }

}
