.header__top {
  box-sizing: border-box;
  height: $headerheightmobile;
  position: relative;

  @media (min-width: $bp-large) {
    height: $headerheight;
  }
}

.header-top__wrapper {
  height: 100%;
}

.header__nav {
  display: flex;
  height: 100%;
  width: 100%;
  //@media(max-width: 799px) {
  //transform: translateZ(0);
  //}
}

.header-nav__logo {
  align-self: flex-end;
  z-index: 9999; // to be above mobile-nav top border
  @media (max-width: $bp-large - 1) {
    width: 100%;
  }

  @media (max-width: $bp-wide - 1) {
    margin-left: 16px;
  }

  @media (min-width: $bp-wide) {
    margin-left: 40px;
    margin-right: 25px;
  }

  .header--home & {
    @media (min-width: $bp-large) {
      display: none;
    }
  }
}


.header-nav-logo__link {
  background-color: $headerbackground;
  display: block;
  margin-bottom: -$headerbordermobile;
  position: relative;
  width: 69px;
  z-index: 4000;

  &:before,
  &:after {
    background-color: #fff;
    bottom: 0;
    content: '';
    display: block;
    height: $headerbordermobile;
    left: -1px;
    position: absolute;
    width: 3px;
  }

  &:before {
    left: auto;
    right: -1px;
  }

  @media (min-width: $bp-large) {
    margin-bottom: -21px;
    width: 139px;

    &:before,
    &:after {
      height: $headerborder;
      width: 6px;
    }
  }
}

.button-search {
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  border-radius: 0;
  border-width: 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: 'Gotham Narrow';
  font-size: 16px;
  height: $headerheightmobile;
  justify-content: center;
  margin-bottom: 0;
  min-width: $headerheightmobile;
  padding: 0;
  white-space: nowrap;

  &:focus {
    outline: 0 none;
  }

  img {
    height: 24px;
    width: 24px;

    @media (min-width: 800px) {
      height: 39px;
      width: 35px;
    }
  }

  @media (max-width: 800px) {
    margin-bottom: 6px !important;
  }

  @media (max-width: $bp-large) {
    padding: 0;
  }

  .search__button--open {
    align-items: center;
    display: flex;
  }
}

@media (max-width: $bp-desktop) and (min-width: $bp-large) {
  .button-search--standard {
    justify-content: end;
    margin-bottom: -6px !important;
  }
}

@media (max-width: $bp-desktop) and (min-width: $bp-large) {
  .button-search--home-large {
    margin-bottom: 17px;
  }
}

.sticky-nav-button-search {
  @media (max-width: $bp-desktop + 1) {
    margin-bottom: 0;
  }
}

.sticky-nav-margin {
  @media (max-width: $bp-desktop) and (min-width: $bp-large) {
    margin-bottom: -30px !important;
  }
}

.button-search__label {
  @media (max-width: $bp-large - 1) {
    margin-right: 16px;
  }

  @media (min-width: $bp-large) {
    display: none;
  }
}

.mobile-nav {
  align-self: center;
  background-color: transparent;
  border-radius: 0;
  border-width: 0;
  height: $headerheightmobile;
  min-width: $headerheightmobile;
  //  margin-right: -(($headerheightmobile - 24) / 2) - 2;
  &:focus {
    outline: 0 none;
  }

  img {
    margin: 0 auto;
    width: 24px;
  }

  @media (min-width: $bp-large) {
    display: none;
  }
}

.mobile-nav--close {
  background-color: #fff;
}

.main-nav--modular {
    color: #fff;
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-left: 14px;
    width: 100%;

    @media (max-width: $bp-desktop) {
        padding-bottom: 15px;
    }

    @media (max-width: $bp-large - 1) {
        -webkit-overflow-scrolling: touch;
        background-color: $headerbackground;
        border-top: $headerbordermobile solid #fff;
        bottom: 0;
        height: calc(100vh - 50px);
        // position: absolute;
        overflow-y: scroll;
        position: fixed;
        top: 50px;
        transform: translateX(100%);
        transition: transform 250ms;
        z-index: 9999;

        .mobile-nav-open & {
            transform: translateX(0);
        }
    }

    @media (min-width: $bp-large) {
        display: flex;
        margin-top: 16px;
    }
}

.main-nav--modular-top {
  @media (max-width: $bp-desktop) {
    padding-bottom: 0 !important;
  }
}

.main-nav--modular-bottom {
  @media (max-width: $bp-desktop) and (min-width: $bp-large) {
    padding-bottom: 0 !important;
  }
}

.main-nav-item__wrapper {
  position: relative;

  @media (min-width: $bp-large) {
    height: 100%;
  }
}

.main-nav__item {
  margin-bottom: -15px;
  margin-top: 26px;
  max-width: none;

  @media (min-width: 1045px) {
    padding-bottom: 11px;
    padding-right: 35px;
  }

  @media (max-width: $bp-large - 1) {
    border-bottom: 1px solid $gray-darker;
  }

  @media (min-width: $bp-large) {
    border-bottom: 6px solid transparent;
    padding-bottom: 8px;
    white-space: nowrap;

    &:hover {
      border-bottom: 6px solid #fff;
    }
  }

  .header--standard &.is-homelink {
    @media (min-width: $bp-large) {
      display: none;
    }
  }

  &:last-child {
    @media (min-width: $bp-large) {
      flex-basis: 0; // fit longer label in
    }

    @media (min-width: $bp-wide) {
      -ms-flex-preferred-size: 180px !important;
      flex-basis: 0;
    }
  }
}

li.main-nav__item.is-homelink {
  @media (min-width: $bp-large) {
    margin-top: 1.7em;
  }

  @media (max-width: $bp-large) {
    margin-top: 4em;
  }
}

.main-nav__item--standard {
  padding-right: 3%;

  @media (max-width: 865px) and (min-width: $bp-large) {
    padding-right: 0;
  }
}

.main-nav__link,
.main-nav__label {
  cursor: pointer;
  display: block;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  padding: 20px;
  text-decoration: none;
  width: 100%;

  .lang-maori {
    display: block;
    font-weight: 300;
  }


  @media (min-width: $bp-large) {
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 20px 20px 0;
  }

  @media (max-width: 1000px) {
    font-size: 100%;
    padding: 20px 1.2em 0 !important;
  }
}

a.main-nav__link {
  padding-left: 6px;
}

.main-nav__label {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: $white;
  font-family: inherit;
  line-height: 1.4;
  position: relative;
  text-align: left;

  &:focus {
    outline: 0 none;
  }

  @media (max-width: $bp-large - 1) {
    padding-right: 60px;

    &:after {
      background: url('/Client/IMG/Modular/icons/chevron-down-white.svg') no-repeat center center;
      bottom: 0;
      content: '';
      overflow: hidden;
      position: absolute;
      right: 0;
      text-indent: -999%;
      top: 0;
      width: 58px;
    }

    .main-nav__item.is-open &:after {
      background-image: url('/Client/IMG/Modular/icons/chevron-up-white.svg');
    }
  }
  // @media (min-width: $bp-large) {
  //   display: inline-flex;
  //   flex-direction: column;
  //   font-size: 16px;
  //   height: 100%;
  //   justify-content: center;
  //   padding: 20px;
  //   .main-nav__item:hover & {
  //     box-shadow: inset 0 -3px 0 #fff;
  //   }
  // }
}


// .button-subnav {
//   background: url('/Client/IMG/Modular/icons/chevron-down-white.svg') no-repeat center center;
//   background-color: transparent;
//   border-width: 0;
//   bottom: 0;
//   content: '';
//   overflow: hidden;
//   position: absolute;
//   right: 0;
//   text-indent: -999%;
//   top: 0;
//   width: 58px;

//   &:focus {
//     outline: 0 none;
//   }

//   .main-nav__item.is-open & {
//     background-image: url('/Client/IMG/Modular/icons/chevron-up-white.svg');
//   }

//   @media (min-width: $bp-large) {
//     display: none;
//   }
// }

.cta-nav__container-home {
    margin-left: -15px;
  max-width: 1635px;
  position: absolute;
  width: 100%;
  z-index: 100;

  @media (max-width: 1628px) {
    max-width: 1355px !important;
  }

  @media (max-width: $bp-large) {
    max-width: 800px;
    top: 0;
  }
}

.cta-nav__container {
  max-width: 1444px;
  position: absolute;
  width: 100%;
  z-index: 100;

  @media (max-width: 1644px) and (min-width: 1629px) {
    max-width: 1435px;
  }

  @media (max-width: 1628px) {
    max-width: 1160px !important;
  }

  @media (max-width: 1370px) and (min-width: $bp-large) {
    max-width: 1150px !important;
    width: 85%;
  }

  @media (max-width: $bp-wide) {
    max-width: none;
    right: 0;
  }

  @media (max-width: $bp-large) {
    max-width: 800px;
    top: 0;
  }
}

.cta-nav {
  width: 100%;
}

.cta-nav__item {
  display: -webkit-flex;
  display: flex;
  float: right;
  font-size: 100%;
  list-style: none;
  margin-bottom: 0;
  margin-right: 0;
  margin-top: -16px;


  @media (max-width: $bp-large) {
    float: none;
    font-size: 100%;
    margin-left: 0;
    margin-top: 0;
    width: 100%;
  }
}

.cta-nav__link {
  background: none;
  border-width: 0;
  color: #fff;
  cursor: pointer;
  font-size: inherit;
  font-weight: bold;
  padding: 7px 20px;
  text-decoration: none;
  text-transform: uppercase;

  @media (max-width: $bp-medium ) {
    padding: 7px 10px;
  }


  &:focus {
    outline: none;
  }

  @media (max-width: $bp-large) {
    height: 55px;
    width: 100%;
  }
}


li.cta-nav__label {
  margin-bottom: 0;
}

.cta-nav__label {
  border-bottom-width: 1px;
  border-color: rgba(255, 255, 255, 0.5);
  border-left-width: 0;
  border-right-width: 1px;
  border-style: solid;
  border-top-width: 1px;
}

  .cta-nav__label:first-child {
    border-left-width: 1px;
  }

@media (max-width: $bp-large) {
  .cta-nav__label {
    width: 100%;
  }
}


.main-subnav {
  background-color: $gray-darker;
  display: none;
  overflow: hidden;
  width: 100%;

  @media (min-width: $bp-large) {
    background: linear-gradient(to right, $gray-dark 50%, $gray-darker 50%, $gray-darker 50%);
    border-top: $headerborder solid #fff;
    box-shadow: 0 4px 13px 0 rgba(#000, 0.5);
    left: 0;
    position: absolute;
    top: 100%;
    z-index: 2;
  }

  @media (min-width: $bp-desktop) {
    .main-nav__item.hover & {
      display: block;
    }
  }
}

.sub-nav-top {
  top: 113%;
}

.main-subnav__wrapper {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: $pagemaxwidth;

  @media (max-width: $bp-large - 1) {
    flex-direction: column-reverse;
  }
}

.main-subnav__list {
  list-style-type: none;
  margin: 0;
  padding: 16px;

  @media (min-width: $bp-large) {
    min-width: $sidebarwidth;
    padding: 50px;
    padding-left: 20px;
    width: $sidebarwidth;
  }

  @media (min-width: $bp-wide) {
    padding-bottom: 70px;
    padding-left: 0;
    padding-right: 70px;
    padding-top: 70px;
  }
}

.main-subnav__grid {
  background-color: $gray-darker;
  padding: 20px;
  width: 100%;

  @media (max-width: $bp-small) {
    min-height: 200px;
  }

  @media (max-width: $bp-medium) {
    min-height: 300px;
  }

  @media (max-width: $bp-large) {
    min-height: 400px;
  }

  @media (min-width: $bp-large) {
    -ms-grid-columns: 1fr 1fr;
    -ms-grid-rows: 230px 230px;
    display: grid;
    display: -ms-grid;
    grid-gap: 4px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 230px);
    padding: 50px;
  }

  @media (min-width: $bp-wide) {
    padding-bottom: 70px;
    padding-left: 70px;
    padding-right: 0;
    padding-top: 70px;
  }
}

.main-subnav__item {
  margin: 0 0 4px;

  @media (max-width: 1278px) {
    white-space: normal;
  }

  @media (max-width: $bp-large - 1) {
    border-bottom: 1px solid $gray;

    &:first-child {
      border-top: 1px solid $gray;
    }
  }
}

.main-subnav__link {
  color: #fff;
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
  padding: 12px 16px 12px 0;
  text-decoration: none;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}

.main-subnav-grid__link {
  display: block;
  height:100%;

  @media (min-width: $bp-large) {
    margin-bottom: 4px;
    margin-right: 4px;

    @supports (grid-gap: 4px) {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  @media (max-width: $bp-large - 1) {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @media (min-width: $bp-large) {
    &:nth-child(1) {
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }

    &:nth-child(2) {
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }

    &:nth-child(3) {
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }

  @media (min-width: $bp-wide) {
    &:nth-child(1) {
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      grid-column: 1 / 2;
      grid-row: 1 / 3;
    }

    &:nth-child(2) {
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }

    &:nth-child(3) {
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }
}

.main-subnav-grid__item {
    display: block;
    position: relative;
    min-height: 200px;
    height: 100%;

    @media (min-width: $bp-large) {
        overflow: hidden;
        position: relative;

        img {
            height: auto;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: auto;
        }
    }

    img {
        object-fit: cover;
    }

    figcaption {
        background-color: rgba(#000, 0.5);
        bottom: 0;
        color: #fff;
        // IE11 doesn't handle align-items with min-height
        // can't use fixed height for hover effect
        display: table;
        font-size: 18px;
        line-height: 1.2;
        min-height: 100%;
        padding: 16px;
        position: absolute;
        transition: min-height 350ms;
        width: 100%;

        @supports (display: flex) {
            align-items: flex-end;
            display: flex;
        }

        p {
            display: table-cell;
            vertical-align: bottom;
            white-space: normal;
        }
    }

    &:hover {
        figcaption {
            min-height: 0;
        }
    }
}

@media (max-width: $bp-large) {
  .mobile-nav-open {
    .header__top {
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: $z-high;
    }
  }
}

:not(.mobile-nav-open) {
  // prevent sticky nav to mess with scrolling when mobile nav is open
  &.sticky-nav {
    .header {
      padding-top: $headerheightmobile;
      //@media (min-width: $bp-large) {
      // padding-top: $headerheight; //translateY(-$headerheight);
      //}
    }

    .header__top {
      background-color: #000;
      border-bottom: $headerbordermobile solid #fff;
      box-sizing: content-box;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      transform: translateY(-100%);
      z-index: $z-high;


      @media (min-width: $bp-large) {
        border-width: $headerborder;
        height: 104px;
        transform: translateY(-116%);
      }
    }
  }

  &.sticky-nav-show {
    .header__top {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
      transform: translateY(0);
      transition: transform 200ms ease-out;
      -webkit-transform: translateZ(2px) !important;

      @media (min-width: $bp-large) {
        padding-bottom: 15px;
      }
    }
  }
}



@mixin horz-list {
  margin: 12px -10px;

  li {
    display: inline;
    margin: 0 10px;
  }
}

@mixin no-bullets {
  ul {
    list-style: none;
  }
}
/////////////////////////////////////
// Footer
/////////////////////////////////////
.footer-wrapper {
  background: $black;
  clear: both;
  overflow: hidden;
  padding: 30px 0;

  ul {
    a:visited {
      color: $white;
    }

    a:link {
      color: $white;
    }

    a:hover {
      color: $gray-lighter;
    }

    ul {
      a:visited {
        color: $gray-light;
      }

      a:link {
        color: $gray-light;
      }

      a:hover {
        color: $gray-lighter;
        text-decoration: underline;
      }
    }
  }
}

.footer {
  color: $white;

  @media only screen and (min-width: 768px) {
    .footer-nav-shell {
      position: relative;
    }
  }

  .logo-footer {
    height: auto;
    max-width: 198px;
    width: 100%;

    @media only screen and (max-width: 767px) {
      display: block;
      margin: 10px auto 20px;
    }

    @media only screen and (min-width: 768px) {
      bottom: 15px;
      height: 87px;
      position: absolute;
      right: 0;
      width: auto;
    }
  }

  ul.footer-nav {
    display: table;
    // float: left;
    margin: 0;

    li {
      display: table-cell;
      font-style: normal;
      // font-weight: 700;
      line-height: 1.4;
      padding-right: 10px;
      text-transform: uppercase;
      width: 15%;


      ul {
        display: block;
        margin: 12px 0 0;
        padding: 12px 0 0;
        width: 100%;

        li {
          clear: both;
          display: block;
          width: auto;
        }

        a {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          text-transform: inherit;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      > li:nth-last-child(-n + 2) {
        padding-bottom: 120px;
      }
    }
  }

  .btm-nav {
    @include no-bullets;
    @include horz-list;
    float: left;
    margin: 28px -10px;
    text-transform: uppercase;

    a {
      font-weight: 400;
    }
  }

  .social {
    @include no-bullets;
    float: right;

    li {
      background: url('/client/img/AucklandMuseum/icons/icons.png') no-repeat $gray;
      //border-radius: $base-border-radius;
      float: left;
      margin: 6px 0 6px 6px;
      opacity: 0.6;
      overflow: hidden;
      text-indent: -100px;

      &.facebook {
        background-position: 0 -400px;
      }

      &.twitter {
        background-position: 0 -450px;
      }

      &.email {
        background-position: 0 -500px;
      }

      &.instagram {
        background-position: 2px -817px;
      }

      &.pintrest {
        background-position: 0 -550px;
      }

      &.youtube {
        background-position: 0 -600px;
      }

      &.tripadvisor {
        background-position: 0 -650px;
      }

      &.linkedin {
        background-position: 2px -774px;
      }

      a {
        display: block;
        height: 32px;
        width: 32px;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .legal {
    border-top: 1px solid $gray-dark;
    clear: both;
    display: block;
    font-size: 13px;
    margin-left: 0;
    padding-top: 12px;

    li {
      color: #999;
      display: inline;
      margin: 0 10px 0 0;

      &:first-child {
        float: left;
      }

      &:last-child {
        float: right;
        margin: 0;
      }

      a {
        color: $gray-light;

        &:hover {
          color: #ccc;
          text-decoration: underline;
        }
      }
    }
  }
}

a#lnkLogin {
  color: #808080;
}
