.tag {
  background-color: #000;
  color: #fff;
  display: inline-block;
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 1;
  margin-top: 0;
  min-height: 8px;
  min-width: 60px;
  padding: 1rem 2rem;
  // text-transform: uppercase;

  .object--dark & {
    background-color: $c-background-grey;
    color: $black;
  }

  .reversed & {
    background-color: #fff;
    color: #000;
  }
}

// status difers from tag in that it's the colour of the text not the el's background
// that is coloured so be mindful of what it is over
.status-black,
.status-gray,
.status-gold,
.status-red,
.status-orange,
.status-yellow,
.status-green-dark,
.status-turquoise,
.status-blue-light,
.status-blue-dark,
.status-purple,
.status-pink {
  text-transform: uppercase;
}

.status-black {
  color: $c-status-black;
  //background-color: $c-status-black;
}

.status-gray {
  color: $c-status-gray;
  //background-color: $c-status-gray;
}

.status-gold {
  color: $c-status-gold;
  //background-color: $c-status-gold;
}

.status-red {
  color: $c-status-red;
  //background-color: $c-status-red;
}

.status-orange {
  color: $c-status-orange;
  //background-color: $c-status-orange;
}

.status-yellow {
  color: $c-status-yellow;
  //background-color: $c-status-yellow;
}

.status-green-dark {
  color: $c-status-green-dark;
  //background-color: $c-status-green-dark;
}

.status-turquoise {
  color: $c-status-turquoise;
  //background-color: $c-status-turquoise;
}

.status-blue-light {
  color: $c-status-blue-light;
  //background-color: $c-status-blue-light;
}

.status-blue-dark {
  color: $c-status-blue-dark;
  //background-color: $c-status-blue-dark;
}

.status-purple {
  color: $c-status-purple;
  //background-color: $c-status-purple;
}

.status-pink {
  color: $c-status-pink;
  //background-color: $c-status-pink;
}
