.status {
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  padding: 10px;
  text-transform: uppercase;
  white-space: nowrap;

  .box figure &,
  .box .thumb & {
    bottom: 0;
    left: 0;
    opacity: 0.9;
    position: absolute;
    width: 100%;
  }
}

// Colours Taken from Digital Toolkit
// http://www.aucklandmuseum.com/toolkit/building-blocks/colours

$colours: (
  'black': #000,
  'gray': #6d6d6d,
  'gold': #cc3,
  'red': #c03,
  'orange': #f60,
  'yellow': #ff0,
  'green-dark': #093,
  'turquoise': #14a79d,
  'blue-light': #0cc,
  'blue-dark': #009,
  'purple': #609,
  'pink': #f39
);


@each $key, $value in $colours {
  .status-#{$key} {
    background-color: $value;
  }
}


.extra-info {
  //margin-bottom: 10px;
  align-items: flex-start;
  display: block; // Fallback for older broswers
  display: flex;
  flex-direction: row;

  .date-location,
  .status {
    display: inline-block;
    margin-bottom: 6px;
    margin-right: 10px;
    vertical-align: top;
  }
}
