.belt {
    background-color: #f5f5f5;

    &.white {
        background-color: #fff;
    }

    &.reversed & {
        background-color: #000;
        color: #bfbfbf;
    }

    &:hover {
        .owl-nav {
            opacity: 1;
        }
    }

    .belt_titlebar {
        position: relative;
        margin-bottom: 4em;
    }

    h4 {
        color: inherit;
        width: auto;
        border-bottom: 0;
        padding-bottom: 0;
        //margin-left: 100px;
        margin-right: 0;
        width: 75%;

        &.belt_title {
            font-weight: 900;
        }

        &.belt_subtitle {
            font-weight: 400;
        }
    }

    .belt_link {
        //border-bottom: 2px solid #030303;
        color: inherit;
        position: absolute;
        text-align: right;
        bottom: 0;
        right: 0;

        a {
            display: inline-block;
            font-size: 4rem;
            font-weight: 400;
            text-underline-offset: 0.25em;
        }
    }

    .poster,
    .repeatable__item {
        height: 100%;
    }

    .repeatable__item {
        //border-bottom: 1px solid #000;
        padding-bottom: 12rem;

        &.light {
            background-color: #f5f5f5;

            .object {
                padding-left: 4em;
            }
        }

        &.white {
            background-color: #fff;

            .object {
                padding-left: 4em;
            }
        }

        &.reversed {
            background-color: #000;
            color: #fff;

            .object {
                padding-left: 4em;
            }
        }
    }

    &:not(.collection) .owl-item:first-child {

        @media (max-width: $bp-wide) {
            display: none;
        }

        .repeatable__item {
            @media (min-width: $bp-wide) {
                border-bottom: 0 none;
            }
        }
    }


    &.collection {
        margin-left: 4rem;
        margin-right: 4rem;

        .page > h4 {
            border-bottom: 0 none;
            font-size: 6.5rem;

            & + .belt_link {
                height: 12rem;
                line-height: 12rem;

                a {
                    font-size: 4.5rem;
                    height: 12rem;
                    line-height: 12rem;
                }
            }
        }

        .box {
            order: 1;
        }

        .owl-item {
            margin-bottom: 5rem;

            h4 {
                border: 0 none;
                font-size: 5rem;
                margin: 4rem 0 0;
                overflow: hidden;
                padding: 0;
                width: 75%;

                @media (min-width: 800px) {
                    //min-height: 4em; // allows 3 lines
                    margin-bottom: 3rem;
                }
            }
        }

        .search-results--info {
            margin-top: 2rem;
        }

        .tag {
            margin-top: 2rem;
            order: 2;
        }
    }

    .object--small {
        margin-top: 4rem;
    }
}


.owl-carousel-3col,
.owl-carousel-small,
.owl-carousel-medium,
.owl-carousel-large,
.owl-carousel {
    margin-top: 5rem;
    position: relative;

    p {
        font-size: 3.5rem;
        margin: 0;
    }
}

.owl-carousel {
    .owl-next,
    .owl-prev {
        top: 20%;
    }

    .owl-next {
        right: 12%;

        @media (max-width: $bp-small) {
            right: 18%;
        }
    }

    .owl-prev {
        left: 4%;
    }
}

.owl-carousel-small {
    p {
        font-size: 3.5rem;
        margin: 0;
        padding-right: 3em;
    }

    .owl-next,
    .owl-prev {
        top: 25%;

        @media (max-width: $bp-small) {
            top: 33%;
        }
    }

    .owl-next {
        right: 6%;

        @media (max-width: $bp-small) {
            right: 7%;
        }
    }

    .owl-prev {
        left: 2%;
    }
}

.owl-carousel-medium {
    p {
        font-size: 4rem;
        margin: 0;
        padding-right: 1em
    }

    .owl-next,
    .owl-prev {
        top: 20%;
    }

    .owl-next {
        right: 12%;

        @media (max-width: $bp-small) {
            right: 18%;
        }
    }

    .owl-prev {
        left: 4%;
    }
}

.owl-carousel-large {
    p {
        font-size: 4.5rem;
        margin: 0;
    }

    .owl-next,
    .owl-prev {
        top: 20%;

        @media (max-width: $bp-small) {
            top: 16%;
        }
    }

    .owl-next {
        right: 12%;

        @media (max-width: $bp-small) {
            right: 18%;
        }
    }

    .owl-prev {
        left: 4%;
    }
}

.owl-nav {
    margin-top: 0;
    opacity: 0.7;
    transition: opacity 250ms ease-out;
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.owl-item {
    float: none;
    margin-top: 0;

    a {
        text-decoration: none;
    }

    figure,
    figure > div {
        height: 200px;
        overflow: hidden;
        position: relative;
        width: 100%;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    .collection & {

        figure,
        figure > div {
            height: 300px;

            @media (min-width: $bp-medium) {
                height: 350px;
            }
        }
    }

    .owl-carousel-small & {
        figure,
        figure > div {
            height: 250px;
        }
    }
}



.owl-next,
.owl-prev {
    background-color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    border: 0;
    font-size: 0;
    height: 48px;
    margin-top: 0;
    position: absolute;
    width: 48px;
    border-radius: 24px;
    background-size: 30px;
    box-shadow: 0px 3px 15px;
    outline: none;
    position: absolute;
    top: 20%;
    pointer-events: visible;

    @media (max-width: $bp-medium) {
        height: 36px;
        width: 36px;
        border-radius: 18px;
    }

    &.disabled {
        display: none;
    }

    .reversed & {
        background-color: #000;
    }
}

.owl-next {
    background-image: url('/Client/IMG/Modular/icons/chevron-right-black.svg');

    .reversed & {
        background-color: #000;
        background-image: url('/Client/IMG/Modular/icons/chevron-right-white.svg');
    }
}

.owl-prev {
    background-image: url('/Client/IMG/Modular/icons/chevron-left-black.svg');

    .reversed & {
        background-image: url('/Client/IMG/Modular/icons/chevron-left-white.svg');
    }
}

.owl-stage {
    display: flex;
    padding-left: 0 !important;
}

.owl-stage-outer {
    -webkit-transform: translate3d(0, 0, 0);
    overflow: hidden;
    position: relative;
}

.belt3Col {
    .repeatable__item {
        border: 1px solid #000;
        margin: 1em;
        padding: 10em;

        .reversed & {
            border-color: #fff;
        }
    }

    p {
        max-width: 100%;
    }

    .owl-next,
    .owl-prev {
        margin: 10px;
        top: 40%;
    }
}
