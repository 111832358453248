.header__breadcrumbs {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  padding: 16px;
}

.breadcrumb__trail {
  margin: 0 auto;
  max-width: $pagemaxwidth;
  padding: 0;
  width: 100%;

  @media (min-width: $bp-wide) {
    padding-left: 0;
    padding-right: 0;
  }

  li {
    color: #a1a1a1;
    display: inline;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 1.6;
    margin-bottom: 0;

    + li {
      &:before {
        content: '/';
        margin: 0 0.75em;
      }
    }

    @media (max-width: $bp-medium - 1) {
      &:nth-last-child(n + 3) {
        display: none;
      }

      &:nth-last-child(2) {
        &:before {
          content: '';
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  a {
    color: #fff;
    text-decoration: none;

    @media (min-width: $bp-desktop) {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.breadcrumb__button-browse,
.breadcrumb__button-close {
  background-color: transparent;
  border-width: 0;
  color: $white;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  padding: 0;

  &:focus {
    outline: 0 none;
  }
}

.breadcrumb__button-close {
  @media (max-width: $bp-large - 1) {
    background: $black;
    display: block;
    position: initial;
    text-align: right;
    width: 100%;
  }

  @media (min-width: $bp-large) {
    position: absolute;
    right: 16px;
    top: 16px;
  }
}

.breadcrumb__button-browse {
  &:after {
    background: url('/Client/IMG/Modular/icons/chevron-down-white.svg') right center no-repeat;
    background-size: cover;
    content: '';
    display: inline-block;
    height: 7px;
    margin: 0 0 0 12px;
    transition: transform 250ms ease-in-out;
    vertical-align: middle;
    width: 12px;
  }

  &[aria-expanded='true'] {
    text-decoration: underline;

    &:after {
      transform: rotate(180deg);
    }
  }
}

.breadcrumb__subnav {
  background-color: #2b2b2b;
  color: #fff;
  display: none;
  position: relative;

  ul {
    list-style-type: none;
    margin: 0 0 0 24px;
  }

  li {
    margin: 0;

    & + li {
      margin-top: 16px;
    }
  }

  .left-nav-shell {
    margin-top: 0;
  }

  h2 {
    font-size: 25px;
    margin: 0;
    padding: 16px 16px 0;
  }

  .subnav { // root <ul>
    margin-left: 0;
    margin-top: 0;
    padding: 32px 16px;

    @media (min-width: $bp-medium) {
      padding: 32px;
    }

    li {
      font-size: 16px;
    }
  }

  .active {
    border-bottom: 2px solid #393939;
    font-weight: 600;
    margin-bottom: 16px;
    padding-bottom: 16px;
  }

  .is-current {
    border-bottom: 2px solid #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
    padding-bottom: 16px;
  }

  a {
    color: #bdbdbd;
    display: block;
    text-decoration: none;

    &:visited {
      color: #bdbdbd;
    }
  }
}

//.breadcrumb-subnav__wrapper {
//  background-color: $gray-darker;
//
//  @media (min-width: $bp-large) {
//    // apply meganav sidebar width minus header borders to align meganav content with breadcrumb subnav content
//    margin-left: $sidebarwidth - $headerborder;
//  }
//}
