.search-options {
  //@extend %box-sizing-border-box;
  border: solid #999;
  border-width: 0 1px;
  overflow: hidden;
  position: relative;
  width: 100%;

  .reset {
    font-size: 3.75rem;
  }

  .field {
    box-sizing: border-box;
    display: inline-block;
    margin: -1.1rem 4rem 0 0;
    vertical-align: middle;
  }

  label {
    display: inline-block;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    vertical-align: middle;
  }

  input[type='checkbox'] {
    display: inline-block;
    line-height: 1;
    margin: 0;
    padding: 0;
    vertical-align: middle;
  }

  .check {
    margin-left: 10px !important;
  }
}

.search-results--title {
  h2 {
    font-size: 6.25rem;
    font-weight: 700;
    margin: 0 0 2.5rem;
  }
}
