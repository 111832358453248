* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

//* + * {
//  margin-top: 4rem;
//}

html {
  font-size: 4px;
}

body {
  color: #000;
  font-family: 'Gotham Narrow';
}

h1 {
  font-size: 8rem;
  max-width: 100%;

  @media (min-width: $bp-medium) {
    font-size: 12rem;
  }

  @media (min-width: $bp-large) {
    font-size: 18.75rem;
  }

}

h2 {
  font-size: 8rem;
  line-height: 1.2;
  max-width: 100%;

  @media (min-width: $bp-medium) {
    font-size: 10rem;
  }

  @media (min-width: $bp-large) {
    font-size: 18.75rem;
    font-weight: 400;
  }
}

h3 {
  font-size: 6rem;
  letter-spacing: 0.3px;
  line-height: 1.35;
  max-width: 100%;

  @media (min-width: $bp-medium) {
    font-size: 8rem;
  }

  @media (min-width: $bp-desktop) {
    font-size: 10rem;
  }
}

h4 {
  border-bottom: 2px solid #000;
  font-size: 4.5rem;
  font-weight: 400;
  padding-bottom: 2.5rem;
  width: 100%;

  @media (min-width: $bp-medium) {
    font-size: 6.25rem;
  }
}

h5 {
  color: inherit;
  font-size: 4rem;
  text-transform: uppercase;
}


//h6 {}

//p {
//  margin-left: auto;
//  margin-right: auto;
//  max-width: 66.666%;
//}

p {
  margin-bottom: 1em;
  max-width: 600px;

  h4 + & {
    margin-top: 6rem;
  }
}

p,
li {
  font-size: 4rem;
  letter-spacing: 0.4px;
  line-height: 1.6;
}

.body--large {
  font-size: 4rem;
  //font-weight: 600;
  margin-top: 0;

  & + p {
    margin-top: 6rem;
  }

  * + & {
    margin-top: 15rem;
  }

  @media (min-width: $bp-medium) {
    font-size: 4.7rem;
  }

  @media (min-width: $bp-large) {
    font-size: 6.25rem;
    font-weight: 400;
  }
}


.pullout {
  border: 2px solid #000;
  font-size: 6em;
  font-weight: 400;
  margin: 0;
  max-width: 100%;
  padding: 1em;
  text-align: center;

  @media (min-width: $bp-medium) {
    font-size: 7rem;
    padding: 2em;
  }

  @media (min-width: $bp-large) {
    font-size: 8rem;
    font-weight: 400;
    padding: 3em;

    .dancefloor__content & {
      padding: 1em;
    }
  }
}


a {
  color: inherit;
}


.copy {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;

  .full-bleed & {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  h3 {
    margin: 0 auto;
    text-align: center;

    @media (min-width: $bp-large) {
      margin: 0 auto;
      max-width: 650px;
    }
  }

  p {
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $bp-large) {
      max-width: 650px;
      text-align: center;
    }
  }


  .left &,
  .right & {
    padding: 5rem 15rem 15rem;

    p {
      text-align: left;
    }
  }

  .body--large {
    text-align: center;

    @media (min-width: $bp-large) {
      max-width: 800px;
    }
  }
}

[hidden] {
  display: none !important;
}
