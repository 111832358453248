.dancefloor {
  margin-left: -$sectionsidepaddingmobile;
  margin-right: -$sectionsidepaddingmobile;

  @media (min-width: 370px) {
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: 800px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  figure {
    display: block;
    width: 100%;
  }
}

.dancefloor--left {
  align-items: flex-start;
}

.dancefloor--right {
  align-items: flex-end;
}

.dancefloor__header {
  background-color: #ededed;
  padding: 4rem 4rem 8rem;

  @media (min-width: 900px) {
    padding: 10rem 10rem 20rem;
    width: 50%;
  }

  @media (min-width: 1200px) {
    padding: 15rem 15rem 30rem;
  }
}

.dancefloor__title {
  border-top: 2px solid #000;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 1.2;
  max-width: 500px;
  padding-top: 4rem;

  @media (min-width: $bp-medium) {
    font-size: 7rem;
  }

  @media (min-width: 900px) {
    border-top-width: 6px;
    font-size: 8rem;
    padding-top: 10rem;
  }

  @media (min-width: 1200px) {
    font-size: 10rem;
  }
}

.dancefloor__image {
  margin-top: 0;
  position: sticky;
  top: 0;
  z-index: 1;

  @media (min-width: 800px) {
    margin-left: 0;
    margin-right: 0;
    max-height: 80vh;
    width: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.dancefloor__content {
  background-color: #ededed;
  margin-top: 0;
  padding: 5rem 5rem 8rem;
  position: relative;
  transform: translate3d(0, 0, 0);
  z-index: 200;

  @media (min-width: 900px) {
    padding: 10rem;
    width: 50%;
  }

  @media (min-width: 1200px) {
    padding: 15rem;
  }

  img + p {
    margin-top: 15rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
  }
}
