.gallery {
  margin-top: 10rem;
  overflow: hidden;
  width: 100%;

  figure {
    display: block;
  }

  img {
    height: 100%;
    object-fit: cover;
  }

  .repeatable__item {
    margin-top: 0;
  }
}

.gallery-item--std,
.gallery-item--dbl {
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  display: block;
  float: left;
  height: 225px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (min-width: $bp-medium) {
    width: 50%;
  }

  @media (min-width: $bp-desktop) {
    height: 277px;
  }
}

.gallery-item--std {

  @media (min-width: $bp-large) {
    width: 25%;
  }
}

.gallery-item--contain {
  img {
    object-fit: contain;
  }
}

// From here down to the bottom is all the old collections gallery overlay code
// that I've ported over from the old site.

$gray-darker:            #222;
$gray-light:             #999;
$gray-lighter:           #ccc;
$white:                  #fff;

// *! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license * /
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  border: 0;
  margin: 0;
  outline: none;
  padding: 0;
  vertical-align: top;
}

.fancybox-wrap {
  border: 1px solid $gray-darker;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 8020;
}

.fancybox-skin {
  background: none;
  border-radius: 4px;
  color: #eee;
  position: relative;
  text-shadow: none;
}

.fancybox-opened {
  background: #fff;
  z-index: 8030;

  .fancybox-title {
    display: none;
  }

  .fancybox-skin {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  }
}

.fancybox-outer,
.fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  font: 14px/20px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-image,
.fancybox-iframe {
  display: block;
  height: 100%;
  width: 100%;
}

.fancybox-image {
  max-height: 100%;
  max-width: 100%;
}

#fancybox-loading {
  background-position: 0 -108px;
  cursor: pointer;
  left: 50%;
  margin-left: -22px;
  margin-top: -22px;
  opacity: 0.8;
  position: fixed;
  top: 50%;
  z-index: 8060;

  div {
    background: url('/Client/Javascript/AucklandMuseum/lib/fancybox/fancybox_loading.gif') center center no-repeat;
    height: 44px;
    width: 44px;
  }
}

.fancybox-close {
  background-image: url('/Client/IMG/Modular/icons/close-white.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  height: 30px;
  margin-top: 0;
  position: fixed;
  right: 30px;
  top: 20px;
  width: 30px;
  z-index: 8040;
}

.fancybox-enlarge {
  background-image: url('/Client/IMG/Modular/icons/zoom-white.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  height: 30px;
  position: fixed;
  right: 30px;
  top: 80px;
  width: 30px;
  z-index: 8040;
}

.fancybox-nav {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  height: 20%;
  position: fixed;
  text-decoration: none;
  top: 30%;
  width: 10%;
  z-index: 8040;

  span {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    height: 50px;
    margin-top: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    z-index: 8040;
  }
}

.fancybox-prev {
  left: 0;

  span {
    background-image: url('/Client/IMG/Modular/icons/chevron-left-white.svg');
    left: 15px;
  }
}

.fancybox-next {
  right: 0;

  span {
    background-image: url('/Client/IMG/Modular/icons/chevron-right-white.svg');
    right: 30px;
  }
}

.fan {
  cursor: pointer;
  height: 30px;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  width: 30px;
  z-index: 8040;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  left: -99999px;
  max-height: 99999px;
  max-width: 99999px;
  overflow: visible !important;
  position: absolute;
  top: -99999px;
  visibility: hidden;
}

.fancybox-lock {
  overflow: hidden !important;
  width: auto;

  body {
    overflow: hidden !important;
  }

  .fancybox-overlay {
    overflow: auto;
    overflow-y: scroll;
  }
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  background-color: #222;
  display: none;
  height: 100vh !important;
  left: 0;
  margin-top: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw !important;
  z-index: 999;

}

.fancybox-overlay-fixed {
  bottom: 0;
  position: fixed;
  right: 0;
}

.fancybox-title-float-wrap {
  bottom: 0;
  margin-bottom: -35px;
  position: absolute;
  right: 50%;
  text-align: center;
  z-index: 8050;

  .child {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    color: #fff;
    display: inline-block;
    font-weight: bold;
    line-height: 24px;
    margin-right: -100%;
    padding: 2px 20px;
    text-shadow: 0 1px 2px #222;
    white-space: nowrap;
  }
}

.fancybox-title-outside-wrap {
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  background: #000;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 10px;
  position: absolute;
}

.fancybox-title {

  p {
    margin-bottom: 7px;

    &.title {
      font-weight: bold;
    }
  }

  .caption {
    color: $white;
  }

  .credit {
    margin-right: 8px;
  }

  .copyright,
  .credit {
    display: inline;
  }

  .other {
    display: block;
  }

  span {
    color: #999;
    font-size: 12px;
  }

  a {
    color: #4354c4;

    &:visited {
      color: #919bda;
    }

    &:hover {
      color: #4354c4;
      text-decoration: underline;
    }
  }
}

.fancybox-zoomable {
  cursor: zoom-in;
}

.fancybox-content {
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: absolute;
  width: 100%;
  z-index: 8041;
}

.fancybox-extra-info {
  background: #333;
  color: #fff;

  h2,
  p,
  a {
    color: inherit;
    margin-top: 0;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
  }

  .container {
    display: flex;
    height: 100%;
    margin: 0 auto;
    min-height: 15%;
    width: 960px;

    @media only screen and (max-width: 959px) {
      width: 100%;
    }
  }


  .fancybox-copyright {
    float: left;
    padding-top: 33px;
    width: 20px;

    a {
      margin-bottom: 20px;
    }
  }

  .fancybox-caption {
    box-sizing: border-box;
    overflow-x: hidden;
    width: 75%;

    @media only screen and (max-width: 768px) {
      clear: left;
      width: 100%;
    }
  }

  .slidecount {
    color: $gray-lighter;
    float: right;
    margin: 22px 30px;

    @media only screen and (max-width: 440px) {
      display: none;
    }
  }

  .fancybox-actions {
    border-left: 1px solid #3f3f3f;
    margin-top: 0;
    overflow: hidden;
    padding: 0;
    width: 25%;

    @media only screen and (max-width: 768px) {
      border-top: 1px solid #3f3f3f;
      clear: left;
      width: 100%;
    }

    svg {
      display: none;
      margin-right: 6px;
    }

    a {
      align-items: center;
      color: inherit;
      display: flex;
      font-size: 14px;
      height: 50%;
      padding: 4rem;
      text-decoration: none;
      white-space: nowrap;

      & + & {
        border-top: 1px solid #3f3f3f;
      }

      &:hover {
        background-color: #272727;
      }
    }
  }
}


.fancybox-captions {
  font-size: 14px;
  margin: 16px 20px;

  h3,
  h4,
  p,
  li {
    color: #fff;
    margin: 0 0 12px;
    max-width: none;
  }

  li {
    list-style: none;
  }

  .field-source {
    display: block;
    margin-bottom: 15px;
  }

  .additional-caption {
    border-top: 1px solid #3f3f3f;
    padding-top: 16px;

    p {
      margin: 0;
    }

    .label {
      color: $gray-light;
      margin-right: 5px;
    }
  }

  .cc {
    color: $gray-lighter;
    font-size: 11px;

    img {
      display: inline;
      margin-bottom: -3px;
      margin-right: 5px;
      width: auto;
    }
  }
}


// Thumbs

#fancybox-thumbs {
  bottom: 100%;
  left: 0;
  margin-bottom: 4rem;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 8050;
}

#fancybox-thumbs ul {
  display: inline-block;
  left: 50% !important;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  transform: translateX(-50%);
}

#fancybox-thumbs ul li {
  float: left;
  margin-top: 0;
  opacity: 0.5;
  padding: 1px;
}

#fancybox-thumbs ul li.active {
  border: 1px solid #fff;
  opacity: 0.75;
  padding: 0;
}

#fancybox-thumbs ul li:hover {
  opacity: 1;
}

#fancybox-thumbs ul li a {
  background: #111;
  border: 1px solid #222;
  display: block;
  outline: none;
  overflow: hidden;
  position: relative;
}

#fancybox-thumbs ul li img {
  border: 0;
  display: block;
  max-width: none;
  padding: 0;
  position: relative;
}


#fancybox-buttons {
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 8050;

  &.top {
    top: 10px;
  }

  &.bottom {
    bottom: 10px;
  }

  ul {
    background-color: #444;
    border: 1px solid #111;
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
    display: block;
    height: 30px;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 166px;

    li {
      float: left;
      margin: 0;
      padding: 0;
    }

    a {
      background-color: transparent;
      background-image: url('/client/javascript/aucklandmuseum/fancybox/fancybox_buttons.png');
      background-repeat: no-repeat;
      display: block;
      height: 30px;
      opacity: 0.8;
      outline: none;
      text-indent: -9999px;
      width: 30px;

      &:hover {
        opacity: 1;
      }

      &.btnPrev {
        background-position: 5px 0;
      }

      &.btnNext {
        background-position: -33px 0;
        border-right: 1px solid #3e3e3e;
      }

      &.btnPlay {
        background-position: 0 -30px;
      }

      &.btnPlayOn {
        background-position: -30px -30px;
      }

      &.btnToggle {
        background-position: 3px -60px;
        border-left: 1px solid #111;
        border-right: 1px solid #3e3e3e;
        width: 35px;
      }

      &.btnToggleOn {
        background-position: -27px -60px;
      }

      &.btnClose {
        background-position: -56px 0;
        border-left: 1px solid #111;
        width: 35px;
      }

      &.btnDisabled {
        cursor: default;
        opacity: 0.4;
      }
    }
  }
}
