.hero {
  margin-top: 0;
  position: relative;

  figure {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.hero--major {
  @media (min-width: $bp-large) {
    max-height: 90%;
    overflow: hidden;
  }

  @media (max-width: 1250px) {
    overflow: inherit;
  }

  .object {
    max-width: 740px;
  }

  &.hero--left {
    .hero__overlay {
      justify-content: flex-end;
      @media (max-width: 1250px) and (min-width: $bp-desktop) {
        box-sizing: content-box;
      }
    }
  }
}

.hero--minor {
    @media (min-width: $bp-large) {
        display: flex;
        width: 100%;
    }

    .hero--reversed {
        background-color: #000;
        border-color: #fff;
        color: #fff;

        .tag {
            background-color: $c-background-grey;
            color: #000;
        }
    }
}


.hero--small {

    .hero--reversed {
        background-color: #000;
        border-color: #fff;
        color: #fff;

        .tag {
            background-color: $c-background-grey;
            color: #000;
        }
    }
}


.hero__overlay {
    //   background-color: #fff;
    background: $c-background-grey;
    margin-top: 0;
    padding: 12rem 6rem;
    position: relative;
    -webkit-transform: translateZ(1px);

    @media (min-width: $bp-large) {
        width: 50%;
    }

    @media (min-width: $bp-desktop) {
        padding: 0 15rem;
    }

    .hero--major & {
        background-color: $gray-darker;
        border-color: #fff;
        color: #fff;
        height: 100%;
        margin-top: 0;
        top: 0;

        @media (min-width: $bp-large) {
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            position: absolute;

            .object {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
            }
        }

        .tag {
            background-color: $c-background-grey;
            color: #000;
        }
    }

    .hero--minor & {
        // background-color: #ededed;
        padding: 5rem 5rem 8rem;

        @media (min-width: $bp-desktop) {
            padding: 15rem;
        }
    }

    .hero--left & {
        left: 0;
        order: 1;
    }

    .hero--right & {
        order: 2;
        right: 0;
    }
}

.hero__image {
    //align-self: flex-start;
  min-height: 30vh;
    position: relative;
    -webkit-transform: translateZ(0px);

    .hero--minor & {
        position: sticky;
        top: 0;

        @media (min-width: $bp-large) {
            width: 50%;
        }
    }

    .hero--left & {
        order: 2;
    }

    .hero--right & {
        order: 1;
    }
}
