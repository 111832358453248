.column,
.columns {
  float: left;
  padding-left: $gutter / 2;
  padding-right: $gutter / 2;

  section {
    padding: 0;
  }


  &.alpha {
    padding-left: 10px;
  }

  &.omega {
    padding-right: 10px;
  }

  &.story-box {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  // undo some fixed width-ness to use all available screen
  @media only screen and (max-width: 440px) {
    &.one,
    &.two,
    &.three,
    &.four,
    &.five,
    &.six,
    &.seven,
    &.eight,
    &.nine,
    &.ten,
    &.eleven,
    &.twelve,
    &.thirteen,
    &.fourteen,
    &.fifteen,
    &.sixteen {
      width: auto;
    }
  }
  // only coloumnate above 600px
  @media (min-width: $bp-medium) {
    .one-third {
      width: 33.333%;
    }

    &.one {
      width: 6.25%;
    }

    &.two {
      width: 12.5%;
    }

    &.three {
      width: 18.75%;
    }

    &.four {
      width: 25%;
    }

    &.five {
      width: 31.25%;
    }

    &.six {
      width: 37.5%;
    }

    &.seven {
      width: 43.75%;
    }

    &.eight {
      width: 50%;
    }

    &.nine {
      width: 56.25%;
    }

    &.ten {
      width: 62.5%;
    }

    &.eleven {
      width: 68.75%;
    }

    &.twelve {
      width: 75%;
    }

    &.thirteen {
      width: 81.25%;
    }

    &.fourteen {
      width: 87.5%;
    }

    &.fifteen {
      width: 93.75;
    }

    &.sixteen {
      width: 100%;

      &.alpha {
        padding-left: 0;
      }

      &.omega {
        padding-right: 0;
      }
    }
  }
}

// Clearing

// Self Clearing Goodness
section:after {
  clear: both;
  content: '\0020';
  display: block;
  height: 0;
  visibility: hidden;
}

// Use clearfix class on parent to clear nested columns,
// or wrap each row of columns in a <div class="row">
.clearfix:before,
.clearfix:after,
.row:before,
.row:after {
  content: '\0020';
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  width: 0;
}

.row:after,
.clearfix:after {
  clear: both;
}

.row,
.clearfix {
  zoom: 1;
}

// You can also use a <br class="clear" /> to clear columns
.clear {
  clear: both;
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  width: 0;
}
