.search-results {
  clear: both;
  overflow: hidden;
}

.search-results--thumbnail {
  box-sizing: border-box;
  margin: 0;
  position: relative;

  .item-view__grid & {
    overflow: hidden;
    // height: 100px; // cut images on collections search reasult grid view on mobile
  }

  @media only screen and (max-width: 767px) {
    height: auto;
    margin-bottom: 2.5rem;
  }

  &.square {
    input {
      width: 100.9%;
    }
  }

  &.landscape {
    &:hover {
      input {
        width: 100.9%;
      }
    }
  }

  &.portrait {
    &:hover {
      input {
        height: 100%;
      }
    }
  }

  img {
    display: block;
    margin: 0 auto;

    .item-view__grid & {
      @media only screen and (min-width: 767px) {
        bottom: 0;
        left: 0;
        margin: auto;
        max-height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        //max-width:
      }
    }
  }
}

a .search-results--thumbnail {
  -moz-transition: opacity 0.2s ease-out;
  -ms-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  -webkit-transition: opacity 0.2s ease-out;
  border: 1px solid $gray-lightess;
  opacity: 0.9;
  transition: opacity 0.2s ease-out;
}

a:hover .search-results--thumbnail {
  -moz-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  -webkit-transition: opacity 0.2s ease-in;
  border: 1px solid $gray-lighter;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}


.search-results--info {
  div {
    //color: #6d6d6d;
    font-size: 3.75rem;
    line-height: 1;
    margin: 0 0 6px;
  }

  span {
    display: block;
    padding: 0 6px 0 0;
  }

  span.highlight {
    display: inline;
    padding: 0;
  }

  strong {
    color: #6d6d6d;
    font-size: 3.75rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}

//.search-results--description {
//  margin: 0;
//}

// Is this in use?
.search-results--category {
  color: #6d6d6d;
  font-size: 14px;
  line-height: 1;
  margin: 0 0 1.5rem;
  text-transform: uppercase;

  a {
    color: inherit;
  }
}

.search-results--title {
  float: left;
}

.search-results--header {
  background: #eee;
  border: 1px solid #ccc;
  clear: both;
  font-size: 3.75rem;
  overflow: hidden;
  padding: 2.5rem 3rem;

  div {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }

  .search-results--count {
    text-align: left;
    width: 40%;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .search-results--display-modifiers {
    text-align: right;
    width: 59%;

    select {
      padding: 1.1rem;
    }

    @media only screen and (max-width: 767px) {
      padding: 2.5rem 0 0;
      text-align: left;
      width: 100%;
    }
  }

  .search-results--per-page,
  .search-results--sort,
  .search-results--sortorder {
    background: #fff;
    margin: 1rem 0;
    text-align: right;
  }

  .search-results--sortorder {
    margin-right: 1.25rem;
  }

  .search-results--view-switcher {
    text-align: right;

    .icon:not(.active) {
      border: 1px solid $gray-light;
      //@include box-shadow(rgba(black, 0.1) 0 0 3px 1px inset);
    }
  }
}


.results__collections {
  margin-top: 10rem;
}

.highlight {
  font-weight: 700;
}



.empty-cart {
  margin: 5rem 0;
}

.button__view-collection {
  margin: 10rem 0 5rem;
}


// IE bloat
.ie8 .search-results--header {
  background: none;
  border: 0;
}

.ie8 .search-results--count,
.ie8 .search-results--display-modifiers {
  background: none;
  display: block;
  text-align: left;
}

.ie8 .search-results--thumbnail {
  //height: auto;
  img {
    width: 100%;
  }
}
