@font-face {
  font-family: 'Gotham Narrow';
  font-style: normal;
  font-weight: 200;
  // src: url('../AucklandMuseum/fonts/GothamNarrow-Light.eot');
  // src: url('../AucklandMuseum/fonts/GothamNarrow-Light.eot?#iefix') format('embedded-opentype'), url('../AucklandMuseum/fonts/GothamNarrow-Light.woff2') format('woff2'), url('../AucklandMuseum/fonts/GothamNarrow-Light.woff') format('woff'), url('../AucklandMuseum/fonts/GothamNarrow-Light.ttf') format('truetype'), url('../AucklandMuseum/fonts/GothamNarrow-Light.svg#GothamNarrow-Light') format('svg');
  src: url('../AucklandMuseum/fonts/GothamNarrow-Light.woff2') format('woff2'),
  url('../AucklandMuseum/fonts/GothamNarrow-Light.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Narrow';
  font-style: normal;
  font-weight: 400;
  // src: url('../AucklandMuseum/fonts/GothamNarrow-Book.eot');
  // src: url('../AucklandMuseum/fonts/GothamNarrow-Book.eot?#iefix') format('embedded-opentype'), url('../AucklandMuseum/fonts/GothamNarrow-Book.woff2') format('woff2'), url('../AucklandMuseum/fonts/GothamNarrow-Book.woff') format('woff'), url('../AucklandMuseum/fonts/GothamNarrow-Book.ttf') format('truetype'), url('../AucklandMuseum/fonts/GothamNarrow-Book.svg#GothamNarrow-Book') format('svg');
  src: url('../AucklandMuseum/fonts/GothamNarrow-Book.woff2') format('woff2'),
  url('../AucklandMuseum/fonts/GothamNarrow-Book.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Narrow';
  font-style: normal;
  font-weight: 600;
  // src: url('../AucklandMuseum/fonts/GothamNarrow-Medium.eot');
  // src: url('../AucklandMuseum/fonts/GothamNarrow-Medium.eot?#iefix') format('embedded-opentype'), url('../AucklandMuseum/fonts/GothamNarrow-Medium.woff2') format('woff2'), url('../AucklandMuseum/fonts/GothamNarrow-Medium.woff') format('woff'), url('../AucklandMuseum/fonts/GothamNarrow-Medium.ttf') format('truetype'), url('../AucklandMuseum/fonts/GothamNarrow-Medium.svg#GothamNarrow-Medium') format('svg');
  src: url('../AucklandMuseum/fonts/GothamNarrow-Medium.woff2') format('woff2'),
  url('../AucklandMuseum/fonts/GothamNarrow-Medium.woff') format('woff');
}
