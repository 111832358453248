// breakpoints
// these also match the sizes in the owl.js file for specifying how many items at different widths
$bp-wide: 1300px;
$bp-desktop: 1024px;
$bp-large: 800px;
$bp-medium: 600px;
$bp-small: 400px;


// colors
$c-background-grey: #f5f5f5;
$c-borders: #b4b4b4;
$c-dark-grey: #333;
$c-mid-grey: #6d6d6d;
$c-light-grey: #cecece;

// status colours
$c-status-black: #000;
$c-status-gray: #6d6d6d;
$c-status-gold: #cc3;
$c-status-red: #c03;
$c-status-orange: #f60;
$c-status-yellow: #ff0;
$c-status-green-dark: #093;
$c-status-turquoise: #14a79d;
$c-status-blue-light: #0cc;
$c-status-blue-dark: #009;
$c-status-purple: #609;
$c-status-pink: #f39;


// z-index
$z-low: 5; // logo
$z-med: 100; // breadcrumbs
$z-high: 400;
$z-top: 600; // search wrap
$z-max: 900;

// Brand extras
// -------------------------
$green: #8bc947; // Tag selected state


// Grays
// -------------------------
$black: #000;
$gray-darker: #222;
$gray-dark: #333;
$gray: #6d6d6d;
$gray-light: #999;
$gray-lighter: #ccc;
$gray-lightess: #eee;
$white: #fff;


// ISO boxes
// -------------------------

$twitter: #00aced;
$twitter-darken: darken($twitter, 5%);
$facebook: #3b5998;
$facebook-darken: darken($facebook, 5%);
$pinterest: #c72527;
$pinterest-darken: darken($pinterest, 5%);
$youtube: #a9382e;
$youtube-darken: darken($youtube, 5%);
$tripadvisor: #75b937;
$tripadvisor-darken: darken($tripadvisor, 5%);
$blog: #ea172a;
$blog-darken: darken($blog, 5%);
$linkedin: #2089be;
$linkedin-darken: darken($linkedin, 5%);

// Header navigation bar
$headerheightmobile: 50px;
$headerheight: 100%;
$headerbordermobile: 3px;
$headerborder: 6px;
$headerbackground: #000;

$headerheroheightmobile: 230px;
$headerheroheight: 462px;

// Subnavigation meganav
$sidebarwidth: 230px;

// Header breadcrumbs
$breadcrumbsheightmobile: 60px;
$breadcrumbsheight: 80px;

// page_width
$pagemaxwidth: $bp-wide;
$sectionsidepaddingmobile: 20px;
$sectionsidepadding: 16rem;


//Forms from old version
$form-element-font: 'Proxima N W15 Cd Reg', Helvetica, Arial, sans-serif;

$form-input-border: 1px solid #ccc;
$form-input-text-colour: #777;
$form-input-background: #fff;
$form-input-padding: 6px 4px;
$form-input-width: 100%;
$form-input-max-width: $bp-medium;
$form-input-select-width: 220px;
$form-input-margin: 0 0 20px 0;
$form-input-font-size: 13px;

$form-input-focus-border: 1px solid #aaa;
$form-input-focus-text-colour: #444;

$form-label-legend-colour: #444;
$form-fieldset-margin: 0 0 20px 0;

$legend-font-weight: bold;
$legend-font-size: $form-input-font-size;

$font-book: 200;
$font-medium: 400;
$font-bold: 600;

$border-light: 1px;
$border-heavy: 5px;

// AKM Colors
// -------------------------

$colour-gold: #cc3;
$colour-red: #c03;
$colour-orange: #f60;
$colour-yellow: #ff0;
$colour-dark-green: #093;
$colour-turquoise: #14a79d;
$colour-light-blue: #0cc;
$colour-dark-blue: #009;
$colour-purple: #609;
$colour-pink: #f39;

// Wash - basic CMYK 10% tint and RGB equivalent
$colour-gold-wash: lighten($colour-gold, 40%);
$colour-red-wash: lighten($colour-red, 40%);
$colour-orange-wash: lighten($colour-orange, 40%);
$colour-yellow-wash: lighten($colour-yellow, 40%);
$colour-dark-green-wash: lighten($colour-dark-green, 40%);
$colour-turquoise-wash: lighten($colour-turquoise, 40%);
$colour-light-blue-wash: lighten($colour-light-blue, 40%);
$colour-dark-blue-wash: lighten($colour-dark-blue, 40%);
$colour-purple-wash: lighten($colour-purple, 40%);
$colour-pink-wash: lighten($colour-pink, 40%);

// // Light - 50% opacity
$colour-gold-light: lighten($colour-gold, 30%);
$colour-red-light: lighten($colour-red, 30%);
$colour-orange-light: lighten($colour-orange, 30%);
$colour-yellow-light: lighten($colour-yellow, 30%);
$colour-dark-green-light: lighten($colour-dark-green, 30%);
$colour-turquoise-light: lighten($colour-turquoise, 30%);
$colour-light-blue-light: lighten($colour-light-blue, 30%);
$colour-dark-blue-light: lighten($colour-dark-blue, 30%);
$colour-purple-light: lighten($colour-purple, 30%);
$colour-pink-light: lighten($colour-pink, 30%);

// // Dark - basic CMYK with K75% and RGB equivalent
$colour-red-dark: darken($colour-red, 25%);
$colour-orange-dark: darken($colour-orange, 25%);
$colour-yellow-dark: darken($colour-yellow, 25%);
$colour-dark-green-dark: darken($colour-dark-green, 25%);
$colour-turquoise-dark: darken($colour-turquoise, 25%);
$colour-light-blue-dark: darken($colour-light-blue, 25%);
$colour-dark-blue-dark: darken($colour-dark-blue, 25%);
$colour-purple-dark: darken($colour-purple, 25%);
$colour-pink-dark: darken($colour-pink, 25%);

// Links
// -------------------------
$link-colour: #000;
$link-colour-hover: darken(#4354c4, 15%);

$gutter: 20px;
