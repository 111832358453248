
// Boxes - homepage, events
.box {
  position: relative;

  figure,
  .thumb {
    position: relative;
  }

  img {
    // float: left;
    display: block;
    width: 100%;
  }

  h3 {
    margin-bottom: 6px;
  }

  h6 {
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 12px;
  }

  span {
    clear: both;
    display: block;
    padding: 12px 12px 6px;
  }

  p {
    margin-bottom: 10px;
  }

  a.cat {
    background: $white;
    color: inherit !important;
    font-size: 13px;
    font-weight: $font-bold;
    line-height: 16px;
    margin-top: 12px;
    padding: 6px 8px;
    position: absolute;
    right: 0;
    text-decoration: none;
    text-transform: uppercase;
    z-index: 1;

    &:hover {
      border-bottom: $border-light solid $black;
      margin-bottom: -1px;
    }
  }

  .link {
    border-bottom: 1px solid $white;
    display: inline;
  }

  .des {
    display: block;
  }
}

.box-thumbnail {
  position: relative;

  @media only screen and (max-width: 767px) {
    display: inline-block;

    div {
      display: inline-block;
    }

    a {
      display: block;
    }
  }
}
