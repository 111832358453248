.global-footer {
  background-color: #000;
  color: #fff;
  font-size: 18px;
  margin-top: 128px;
  padding: 40px 20px 20px;

  @media (min-width: 1200px) {
    font-size: 14px;
    font-weight: 400;
  }

  * {
    box-sizing: border-box;
    font-family: 'Gotham Narrow';
  }

  * + * {
    margin-top: 20px;
  }

  .page {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
  }

  a {
    color: #bdbdbd;
    font-size: 1em;
    font-weight: 600;
    text-decoration: none;

    @media (min-width: 1024px) {
      font-weight: 400;
    }

    &:hover {
      color: #fff;
    }

    &:visited {
      color: #bdbdbd;
    }
  }

  p {
    color: #bdbdbd;
    font-size: 1em;
  }

  img {
    display: block;
    width: 100%;
  }
}

.global-footer__section {
  flex-basis: 100%;
  padding: 0;
  width: 100%;


  &:first-child {
    margin-top: 0;

    @media (min-width: $bp-wide) {
      flex-basis: 16.666%;
      width: 16.666%;
    }
  }

  @media (min-width: $bp-wide) {
    flex-basis: 16.666%;
    width: 16.666%;
  }

  & + & {
    margin-top: 40px;

    @media (min-width: $bp-wide) {
      margin-top: 0;
    }
  }
}


.global-footer__title {
  border-top: 1px solid #fff;
  color: #fff;
  font-size: 1.2em;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 24px;
  text-transform: uppercase;

  @media (min-width: 1200px) {
    margin-right: 16px;
  }
}

.global-footer__list {
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: 450px) {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    font-size: 1em;
    line-height: 1.4;
    margin-bottom: 0;
    margin-top: 16px;
    padding-right: 16px;

    @media (min-width: 450px) {
      flex-basis: 50%;
      width: 50%;
    }

    @media (min-width: 700px) {
      flex-basis: 33.3%;
      width: 33.3%;
    }

    @media (min-width: 1024px) {
      flex-basis: 25%;
      width: 25%;
    }

    @media (min-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
    }
  }
}

.global-footer__partners {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    flex-basis: 22%;
    height: auto;
    margin-top: 0;
    max-width: 150px;
    width: 22%;

    @media (min-width: 1200px) {
      flex-basis: 70%;
      width: 70%;
    }
  }

  a + a {
    @media (min-width: 1200px) {
      margin-top: 32px;
    }
  }

  .partner--council {
    flex-basis: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    max-width: 200px;

    @media (min-width: 850px) {
      flex-basis: 22%;
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      max-width: 150px;
    }

    @media (min-width: 1200px) {
      flex-basis: 70%;
      margin-top: 16px;
      max-width: 200px;
      width: 70%;
    }
  }
}

.global-footer__social {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  justify-content: start;
  margin-top: 2em;
  width: 100%;

  @media (min-width: 1200px) {
    margin-top: 48px !important;
  }
}

.social__link {
  align-items: center;
  background-color: #414141;
  display: flex;
  fill: #fff;
  height: 50px;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 0;
  padding: 6px;
  transition: 250ms background-color ease-in-out;
  width: 50px;

  & + & {
    margin-bottom: 5px;
    margin-left: 5px;
  }

  &:hover {
    background-color: #fff;

    svg {
      fill: #414141;
    }
  }

  svg {
    height: 80%;
    transition: 250ms fill ease-in-out;
    width: 80%;
  }
}

.global-footer__footer {
  border-top: 1px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
  width: 100%;

  @media (min-width: 500px) {
    flex-direction: row;
  }

  a,
  p {
    @media (min-width: 500px) {
      margin-top: 0;
    }
  }
}
