.container > section {
    padding-left: 0;
    padding-right: 0;

    @media (max-width: $bp-wide) {
        padding-left: $sectionsidepaddingmobile !important;
        padding-right: $sectionsidepaddingmobile !important;
    }
}

.page > section {
    padding-left: 0 !important;
    padding-right: 0 !important;

    @media (max-width: $bp-wide) {
        padding-left: $sectionsidepaddingmobile !important;
        padding-right: $sectionsidepaddingmobile !important;
    }
}

section {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 0;
    padding-left: $sectionsidepaddingmobile;
    padding-right: $sectionsidepaddingmobile;
    padding-top: $sectionsidepaddingmobile * 2;
    width: 100%;

    h1 {
        border-top: 5px solid #000;
        font-size: 10.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.1em;
        margin: 10rem 0 3.75rem;
        padding-top: 3rem;
    }

    &.wrapping {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (min-width: $bp-large) {
            margin-left: -3rem;
        }

        @media (min-width: $bp-medium) {
            margin-left: -2rem;
        }
    }

    @media (min-width: $bp-large) {
        padding-left: $sectionsidepadding;
        padding-right: $sectionsidepadding;
        padding-top: $sectionsidepadding;
    }

    @media (min-width: $bp-desktop) {
        padding-top: 22rem;
    }

    &.reversed {
        background-color: #000;

        &.top-margin-10 {
            margin-top: 10rem;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            color: #fff;
        }

        h4 {
            border-bottom-color: #fff;
        }

        p {
            color: #cecece;
        }

        .hero {
            .frame {
                &.active {
                    &:after {
                        border-width: 0 !important;
                    }
                }
            }
        }
    }

    &.light {
        background-color: $c-background-grey;

        &.has-no-top-margin {
            margin-top: 0;
            padding-top: 0;
        }

        &.top-margin-10 {
            margin-top: 0;
            padding-top: 10rem;
        }
    }

    &.white {
        background-color: #fff;

        &.has-no-top-margin {
            margin-top: 0;
            padding-top: 0;
        }

        &.top-margin-10 {
            margin-top: 0;
            padding-top: 10rem;
        }
    }

    .left + .right,
    .right + .left {
        width: 100%;

        @media (max-width: $bp-large) {
            //margin-top: 12rem;
            margin-top: 0;
        }
        // p {
        //   margin-left: auto;
        //   margin-right: auto;
        //   max-width: 520px;
        // }
    }


    .left {
        width: 100%;

        p {
            max-width: 800px !important;
        }

        &.hleft {
            text-align: left;
        }

        &.hcenter {
            text-align: center;
        }

        &.hright {
            text-align: right;
        }

        &.white {
            background-color: #fff;
        }

        @media (min-width: $bp-desktop) {
            margin-top: 0;
            order: 1;

            &.percent25 {
                flex-basis: 25%;
            }

            &.percent33 {
                flex-basis: 33%;
            }

            &.percent50 {
                flex-basis: 50%;
            }

            &.percent66 {
                flex-basis: 66%;
            }

            &.percent75 {
                flex-basis: 75%;
            }
        }
    }


    .right {
        width: 100%;

        p {
            max-width: 800px !important;
        }

        &.hleft {
            text-align: left;
        }

        &.hcenter {
            text-align: center;
        }

        &.hright {
            text-align: right;
        }

        @media (min-width: $bp-desktop) {
            margin-top: 0;
            order: 2;

            &.percent25 {
                flex-basis: 25%;
            }

            &.percent34 {
                flex-basis: 34%;
            }

            &.percent50 {
                flex-basis: 50%;
            }

            &.percent67 {
                flex-basis: 67%;
            }

            &.percent75 {
                flex-basis: 75%;
            }
        }
    }

    &.full-bleed {
        margin-top: 40px;
        padding: 0;

        .lrpadding {
            padding-left: 4rem;
            padding-right: 4rem;

            .left {
                padding-right: 0;

                @media (min-width: $bp-desktop) {
                    padding-right: 2rem;
                }
            }

            .right {
                padding-left: 0;

                @media (min-width: $bp-desktop) {
                    padding-left: 2rem;
                }
            }
        }

        &.light {
            padding-top: 22rem;

            &.has-no-top-margin {
                margin-top: 0;
                padding-top: 0;
            }

            &.top-margin-10 {
                margin-top: 0;
                padding-top: 10rem;
            }
        }

        @media (min-width: $bp-large) {
            margin-top: 22rem;
        }

        > figure {
            display: block;
            height: 80vh;
            margin-top: 0;
            overflow: hidden;

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
    }

    &.has-no-top-margin {
        margin-top: 0;
        padding-top: 0;
    }

    &.top-margin-10 {
        margin-top: 0;
        padding-top: 10rem;
    }
}
