.closer {
  font-size: 16px;
  padding: 16px;
  text-decoration: none;

  &.closer--meganav {
    display: none;

    @media (min-width: $bp-large) {
      color: $white;
      display: block;
      margin-top: 24px;
      padding: 10px 0;
    }
  }

  &:before {
    background: url('/Client/IMG/Modular/icons/close-white.svg') center center no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 20px;
    margin: 0 4px 0 0;
    opacity: 0.75;
    transition: transform 150ms ease-in-out;
    vertical-align: middle;
    width: 20px;
  }
}
