.search {
  display: flex;
  flex: 0;
  justify-content: flex-end;
  margin-bottom: -5px;
  margin-right: 20px;
  width: 100%;
}

.search__section {
  align-items: flex-start;
  background-color: $black;
  border-top: $headerbordermobile solid #fff;
  bottom: 0;
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 132px;
  z-index: 3000;

  @media (max-width: 1000px) {
    top: 120px;
  }

  @media (max-width: $bp-wide - 1) {
    padding: $sectionsidepaddingmobile;
  }

  @media (max-width: $bp-large) {
    top: 50px;
  }

  @media (min-width: $bp-large) {
    align-items: center;
    border-width: $headerborder;
  }

  &.is-open {
    display: flex;
  }

  > .page {
    width: 100%;
  }

  label {
    color: $c-mid-grey;
    display: flex;
    font-size: 16px;
    margin: 0;
    width: 100%;

    @media (min-width: $bp-large) {
      font-size: 26px;
    }
  }

  input[type='text'],
  input[type='search'] {
    background-color: transparent;
    border: 0 none;
    border-bottom: 1px solid $c-mid-grey;
    border-radius: 0;
    color: $c-mid-grey;
    display: inline-block;
    flex-grow: 1;
    font-size: 24px;
    height: 50px;
    margin: 16px 0;
    padding: 4px;

    @media (min-width: $bp-large) {
      background-size: 72px 72px;
      font-size: 64px;
      height: 81px;
      line-height: 72px;
      width: calc(100% - 72px);
    }

    &:focus {
      border-bottom-color: #fff;
      color: #fff;
      outline: 0 none;

      & + input[type='image'] {
        border-bottom-color: #fff;
      }
    }
  }

  input[type='image'] {
    border: 0 none;
    border-bottom: 1px solid $c-mid-grey;
    border-radius: 0;
    color: $c-mid-grey;
    display: inline-block;
    height: 50px;
    margin: 16px 0;
    padding: 4px;
    vertical-align: bottom;
    width: 40px;

    @media (min-width: $bp-large) {
      background-size: 72px 72px;
      font-size: 64px;
      height: 81px;
      line-height: 72px;
      width: 72px;
    }

    &:focus {
      border-bottom-color: #fff;
      color: #fff;
      outline: 0 none;
    }
  }
}

@media (min-width: $bp-large) {
  .sticky-nav-search {
    top: 119px;
  }
}


section {
  .simple-search {
    background: none;
    border: 0 none;
    color: $gray-dark;
    overflow: hidden;
    padding: 4em 0;
    position: relative;

    .simple-search--button {
      background-color: $gray-lightess;
      text-align: center;
      width: 20%;

      @media only screen and (min-width: $bp-large) {
        height: 55px;
        min-width: unset;
        vertical-align: middle;
      }

      @media only screen and (max-width: $bp-large) {
        height: 55px;
        width: 100%;
      }
    }

    .simple-search--input {
      background: $white;
      border: 2px solid $gray-dark;
      box-sizing: border-box;
      float: left;
      height: 55px;
      margin-bottom: 0;
      padding: 0;
      width: 100%;

      @media only screen and (min-width: $bp-desktop) {
        border: 3px solid $gray-dark;
      }

      @media only screen and (min-width: $bp-large) {
        border-right: 0 solid $white;
        display: table-cell;
        padding: 5px;
        width: 80%;
      }

      .simple-search--textbox {
        background: #fff;
        border: 0 none;
        box-sizing: border-box;
        float: left;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
        padding: 10px;

        @media only screen and (max-width: $bp-large) {
          font-size: 16px;
          height: 55px;
          margin-bottom: 12px;
          width: 100%;
        }

        @media only screen and (min-width: $bp-large) {
          background: none;
          border: 0 none;
          font-size: 16px;
          margin: 0;
          outline: none;
        }
      }
    }
  }
}


.object__copy {
  .simple-search {
    background: none;
    border: 0 none;
    color: $gray-dark;
    overflow: hidden;
    padding: 4em 0;
    position: relative;

    .simple-search--button {
      background-color: $gray-lightess;
      margin-top: 0;
      text-align: center;
      width: 30%;

      @media only screen and (min-width: $bp-large) {
        height: 55px;
        min-width: unset;
        vertical-align: middle;
      }

      @media only screen and (max-width: $bp-large) {
        height: 55px;
        width: 100%;
      }
    }

    .simple-search--input {
      background: $white;
      border: 2px solid $gray-dark;
      box-sizing: border-box;
      float: left;
      height: 55px;
      margin-bottom: 0;
      padding: 0;
      width: 100%;

      @media only screen and (min-width: $bp-desktop) {
        border: 3px solid $gray-dark;
      }

      @media only screen and (min-width: $bp-large) {
        border-right: 0 solid $white;
        display: table-cell;
        padding: 5px;
        width: 70%;
      }

      .simple-search--textbox {
        background: #fff;
        border: 0 none;
        box-sizing: border-box;
        float: left;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
        padding: 10px;

        @media only screen and (max-width: $bp-large) {
          font-size: 16px;
          height: 55px;
          margin-bottom: 12px;
          width: 100%;
        }

        @media only screen and (min-width: $bp-large) {
          background: none;
          border: 0 none;
          font-size: 16px;
          margin: 0;
          outline: none;
        }
      }
    }
  }
}


.reversed {
  .simple-search--button {
    border-color: #000;
    color: #000;
  }
}
