.belt.collection p {
  margin-bottom: 1em;
}

.train__content .simple-search--dropdown,
.object__copy .simple-search--dropdown {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  appearance: none;
  background-color: transparent;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 0.65em auto, 100%;
  border: 3px solid #000;
  border-radius: 0;
  box-sizing: border-box;
  color:#000!important;
  display: inline-flex;
  float: none;
  font-family: 'Gotham Narrow';
  font-size: 4rem;
  font-weight: 600;
  height: 13.5rem;
  letter-spacing: 1px;
  line-height: 1.5;
  outline: none;
  padding: 3rem 8rem !important;
  text-transform: uppercase;
}

.Rtable-cell .simple-search--dropdown {
  max-width: 100%;
  width: 100%;
}

.object--reversed .train__content .simple-search--dropdown,
.object--reversed .object__copy .simple-search--dropdown,
.object--reversed .simple-search--dropdown,
.reversed .simple-search--dropdown {
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  border-color: #fff;
  color: #fff;
}

.select--intro {
  font-size: 4rem;
  font-weight: 600;
  padding-right: 3rem;
}

// IE11 
.object__copy select.simple-search--dropdown::-ms-expand {
  display: none;
}
