.Rtable-cell,
.left,
.right {

  .object--promo {
    margin-bottom: 10rem;
    margin-top: 10rem;
    padding-left: 2%;
    padding-right: 2%;

    &.promo-top {
      padding-top: 25%;

      @media (min-width: $bp-small) {
        padding-top: 15%;
      }

      @media (min-width: $bp-medium) {
        padding-top: 15%;
      }

      @media (min-width: $bp-large) {
        padding-top: 90px;
      }
    }

    .object,
    .object__title,
    .object__subtitle,
    .object__copy,
    .object-inner-wrap,
    p {
      max-width: 100%;
    }

    figure {
      margin-left: 50%;
      margin-right: 10%;
      max-width: 200px;
      position: absolute;
      width: 40%;

      img {
        display: unset;
        width: 100%;
      }

      &.promo-top {
        top: 4%;
      }

      &.promo-bottom {
        bottom: 8%;
      }

      @media (min-width: $bp-small) {
        &.promo-top {
          top: 2%;
        }

        &.promo-bottom {
          bottom: 3%;
        }
      }
    }
  }

  .promo--bar {
    &.promo-top {
      @media (max-width: $bp-large) {
        padding-top: 0;
      }
    }

    &.promo-bottom {
      @media (max-width: $bp-large) {
        padding-bottom: 0;
      }
    }
  }
}






.promo--bar {
  &.promo-top {
    @media (max-width: $bp-large) {
      padding-top: 30%;
    }
  }

  &.promo-bottom {
    @media (max-width: $bp-large) {
      padding-bottom: 20%;
    }
  }

  .object__copy {
    .btn {
      background-color: #fff;

      &.reversed {
        background-color: #000;
      }
    }
  }
}


.object--promo {
  position: relative;

  p {
    max-width: 100%;
  }

  @media (min-width: $bp-large) {
    .object-inner-wrap {
      max-width: 60%;
    }


    .object__title,
    .object__subtitle,
    .object__copy {
      max-width: 100%;
    }
  }

  figure + * {
    margin-top: 0;
  }

  .object {
    padding: 8rem 10rem;
  }

  &.promo-top {
    padding-top: 25%;

    @media (min-width: $bp-small) {
      padding-top: 35%;
    }

    @media (min-width: $bp-medium) {
      padding-top: 20%;
    }

    @media (min-width: $bp-large) {
      padding-top: 90px;
    }
  }

  &.promo-bottom {
    padding-bottom: 25%;

    @media (min-width: $bp-small) {
      padding-bottom: 35%;
    }

    @media (min-width: $bp-medium) {
      padding-bottom: 20%;
    }

    @media (min-width: $bp-large) {
      padding-bottom: 90px;
    }
  }

  .object--major .object__title {
    font-size: 10rem;
    font-weight: 600 !important;
    margin: 0;
  }

  .object--major .object__copy {
    margin-top: 5rem;

    .btn {
      margin-top: 0;
    }
  }

  .object--major .object__subtitle {
    margin-top: 5rem;
  }



  figure {
    margin-left: 20%;
    margin-right: 20%;
    max-width: 400px;
    position: absolute;
    width: 60%;

    @media (min-width: $bp-medium) {
      margin-left: 25%;
      margin-right: 25%;
      width: 50%;
    }

    @media (min-width: $bp-large) {
      margin-left: 60%;
      margin-right: 10%;
      width: 30%;
    }

    img {
      display: unset;
      width: 100%;
    }

    &.promo-top {
      top: 10%;

      @media (min-width: $bp-large) {
        top: 0;
      }
    }

    &.promo-bottom {
      bottom: 10%;

      @media (min-width: $bp-large) {
        bottom: 0;
      }
    }
  }
}

.ticket__promo {
  background-color: $c-background-grey;
  border-color: #fff;
  border-style: solid;
  border-width: 0 0 10rem;
  flex: 0 1 100%;

  @media (min-width: $bp-medium) {
    border-width: 0 0 5rem 2rem;
  }

  @media (min-width: $bp-desktop) {
    border-width: 0 0 8rem 3rem;
  }

  .ticket__banner {
    bottom: 0;
    color: #fff;
    font-size: 4rem;
    font-weight: 600;
    height: 15rem;
    left: 0;
    padding-top: 5rem;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }

  .promo__copy {
    padding-bottom: 40rem;
  }

  .button__bar {
    bottom: 0;
    left: 0;
    padding: 4rem;
    position: absolute;
    width: 100%;
  }

  .tag-overlay {
    position: relative;
  }

  p {
    a {
      text-decoration: none;
    }
  }

  hr {
    border-color: #ccc;
    border-style: outset;
    border-width: 1px 0 0;
    margin: 4rem 0;
  }

  @media (min-width: $bp-medium) {
    flex: 0 1 49%;
  }

  @media (min-width: $bp-desktop) {
    flex: 0 1 33%;
  }

  .hero__image {
    -ms-flex-order: 0;
    order: 0;
    width: 100%;

    figure {
      height: 300px;
      object-fit: cover;
      overflow: hidden;
    }
  }

  .tag {
    margin-top: 2rem;
  }

  .object--small {
    margin-top: 2rem;
    padding: 4rem;
  }
}
