
.frame {
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  @media (min-width: $bp-large) {

    &:after {
      border: 0 solid #fff;
      bottom: 0;
      content: '';
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: border-width 350ms ease-in-out;
    }

    &.active {
      &:after {
        border-width: 5rem;
      }
    }
  }
}
