blockquote {
  color: #939393;
  margin: 10rem auto;
  text-align: center;

  @media (min-width: $bp-large) {
    margin-bottom: 0;
    margin-top: 0;
    max-width: 75%;
  }

  p {
    color: inherit;
    font-size: 7rem;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 1.3;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $bp-large) {
      letter-spacing: 0.8px;
    }

    &:before {
      content: '\201C';
    }

    &:after {
      content: '\201D';
    }
  }

  cite {
    color: inherit;
    display: block;
    font-size: 4rem;
    font-style: normal;
    margin-top: 6rem;

    &:before {
      content: ' - ';
    }
  }
}
