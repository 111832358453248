.btn,
.btn:focus,
.btn:hover {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border-color: inherit;
    border-radius: 0;
    border-style: solid;
    border-width: 2px;
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    font-family: 'Gotham Narrow';
    font-size: 4rem;
    font-weight: 600;
    justify-content: center;
    letter-spacing: 1px;
    line-height: 1.5;
    min-width: 140px;
    padding: 3rem;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-rendering: auto;
    text-shadow: none;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    word-spacing: normal;

    svg {
        margin-right: 2rem;
    }

    .reversed & {
        border-color: #fff;
        color: #fff;

        polygon,
        path {
            fill: #fff;
            stroke: #fff;
        }
    }

    @media (min-width: $bp-small) {
        padding: 3rem 12rem;
    }

    @media (min-width: $bp-desktop) {
        border-width: 3px;
    }

    & + & {
        @media (min-width: 500px) {
            margin-left: 4rem;
        }
    }
}

.btn__maintext,
.btn__subtext {
    color: inherit;
    font-size: 4rem;
    margin-top: 0;
}

.btn__subtext {
    font-weight: 400;
    margin-left: 2rem;
}

//Kentico
.MenuButtonImage {
    display: inline !important;
    width: auto !important;
}

.overlay {

    .unibuttonWrapper {
        position: relative;
        color: white;
    }

    .unibuttonWrapperbottomleft {
        //background-color: rgba(0,0,0,0.5);
        position: absolute;
        padding: 4rem;
        bottom: 0;
        left: 0;
        width: 100%;


        .btn__maintext,
        .btn__subtext {
            font-size: 4.5rem;
            font-weight: 900;
            color: white;
        }
    }
}
