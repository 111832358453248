.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon-16 {
  height: 16px;
  width: 16px;
}

.icon-20 {
  height: 20px;
  width: 20px;
}

.icon-30 {
  height: 30px;
  width: 30px;
}

.icon-labeled {
  display: inline-block;
  font-size: inherit;
  line-height: 1;
  vertical-align: middle;

  a {
    display: inline-block;
    vertical-align: middle;
  }

  a[rel='external'] {
    &:after {
      content: '';
    }
  }
}


// Old School, Non SVG Icons.

.scale-with-grid {
  height: auto;
  max-width: 100%;
}

.icon-label {
  display: inline;
  font-size: 14px;
  line-height: 1;
  margin-left: 5px;
  vertical-align: middle;
}

.report-a-problem {
  background: url('/client/img/AucklandMuseum/icons/report.png') no-repeat center center;
  display: inline-block;
  height: 20px;
  margin-right: 2px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 20px;
}

.icon-file {
  background: url('/client/img/aucklandmuseum/icons/file.png') no-repeat center center;
  display: inline-block;
  height: 20px;
  margin-right: 2px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 20px;
}

.icon-delete {
  background: url('/client/img/aucklandmuseum/icons/remove-image.png') no-repeat center center;
  display: inline-block;
  height: 20px;
  margin-right: 2px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 20px;
}

.icon-poppy {
  background: url('/client/img/aucklandmuseum/icons/poppy.png') no-repeat 15px center;

  @media only screen and (max-width: 440px) {
    background-position: 0 center;
  }
}

.icon-story {
  background: url('/client/img/AucklandMuseum/icons/story32.png') no-repeat 10px center;

  @media only screen and (max-width: 440px) {
    background-position: 0 center;
  }
}

.icon-creative-commons {
  background: url('/client/img/AucklandMuseum/icons/creative-commons.png') no-repeat center center;
  display: block;
  height: 20px;
  width: 20px;
}

.icon-group {

  .icon {
    background-color: #ccc;
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    color: transparent;
    display: inline-block;
    font: 0 / 0 serif;
    height: 32px;
    margin-right: -4px;
    text-shadow: none;
    vertical-align: top;
    width: 32px;

    &:hover {
      background-color: #ccc;
    }

    &.active {
      background-color: #fff;
      border: 1px solid $gray;

      &:hover {
        cursor: default;
      }
    }

    //&:last-child {
    //border-left-width: 0;
    //}
  }
}

.icon-compact {
  background-image: url('/client/img/aucklandmuseum/icons/icon-compact.png');
}

.icon-list {
  background-image: url('/client/img/aucklandmuseum/icons/icon-list.png');
}

.icon-grid {
  background-image: url('/client/img/aucklandmuseum/icons/icon-grid.png');
}

.icon-enquire {
  margin: 4px 0 -4px;
}

.media-object {
  overflow: hidden;
  // _overflow: visible;
  zoom: 1;

  .media--image {
    float: left;
    margin-right: 10px;
  }

  .media--content {
    overflow: hidden;
    // _overflow: visible;
    zoom: 1;
  }
}
