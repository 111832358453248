
.faq-c {
  padding: 1em 6rem;
}

.faq-a {
  display: none;
}

.faq-t {
  -moz-transition: 200ms;
  -ms-transform: rotate(45deg);
  -webkit-transition: 200ms;
  color: #aaa;
  float: left;
  font-weight: 600;
  margin-left: -6rem;
  transform: rotate(45deg);
  transition: 200ms;
}

.faq-o {
  -moz-transition: 200ms;
  -ms-transform: rotate(0deg);
  -ms-transform-origin: 50% 50%;
  -webkit-transform: rotate(-45deg);
  -webkit-transform-origin: 50% 50%;
  -webkit-transition: 200ms;
  transform: rotate(0deg);
  transform-origin: 50% 50%;
  transition: 200ms;

  h4 {
    text-indent: -6rem;
  }
}
