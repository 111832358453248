.where-to-next {

  & > div:first-of-type {
    display: flex;
    flex-wrap: wrap;
  }

  .poster__content {
    align-items: flex-end;
    display: flex;
    padding: 8.75rem;
  }

  .object__title {
    font-size: 6.25rem;
    margin-top: 3rem;
  }

  figure {
    display: block;
    height: 100%;
    overflow: hidden;
  }

  a {
    display: block;
    height: 100%;
  }

  .poster {
    display: block;
    height: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
  }
}

.where-to-next__title {
  margin-bottom: 12rem;
  text-align: center;
  width: 100%;
}

.where-to-next__item {
  color: #fff;
  margin-top: 0;
  // first one bigger
  min-height: 250px;
  width: 100%;


  @media (min-width: 700px) {

    min-height: auto;

    figure {
      height: 0;
      position: relative;
      width: 100%;
    }

    img {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &:nth-child(1) {
      width: 100%;

      figure {
        padding-bottom: 50%;
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: 50%;

      figure {
        padding-bottom: 100%;
      }
    }

    &:nth-child(3) {
      border-left: 1px solid #fff;
    }
  }

  @media (min-width: 1000px) {
    &:nth-child(1) {
      width: 50%;
    }

    &:nth-child(2) {
      width: 25%;
    }

    &:nth-child(3) {
      width: 25%;
    }
  }

  & > div:first-of-type {
    height: 100%;
  }

  h3 {
    font-size: 4.5rem;
    font-weight: 400;

    @media (min-width: $bp-medium) {
      font-size: 6.25rem;
    }
  }

  & + & {
    border-top: 1px solid #fff;

    // next ones smaller
    max-height: 175px;
    min-height: 150px;

    @media (min-width: 700px) {
      max-height: initial;
      min-height: initial;
    }


    @media (min-width: 1000px) {
      border-left: 1px solid #fff;
      border-top: 0 none;
    }
  }

  @media (min-width: $bp-large) {
    width: auto;
  }
}
