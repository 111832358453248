.page-header {
  background-color: #d8d8d8;
  margin-top: 0;
  padding: 6rem;

  @media (min-width: $bp-large) {
    padding: 8rem 0;
  }
}

.page-header__title {
  @media (min-width: $bp-large) {
    max-width: 50%;
  }
}
